<div class="d-flex justify-content-center pagination">
  <button [disabled]="isFirstPage()" (click)="goBackwards()" class="{{ isFirstPage() ? 'not-allowed-arrow' : 'arrow' }} border">
    <i class="fa fa-backward {{ isFirstPage() ? 'not-allowed' : '' }}"></i>
  </button>
  <span class="seite">{{ 'Seite' | translate }}: {{
    page }}</span>
  <button [disabled]="isLastPage()" (click)="goForwards()" class="{{ isLastPage()  ? 'not-allowed-arrow' : 'arrow' }} border">
    <i class="fa fa-forward {{ isLastPage()  ? 'not-allowed' : '' }}"></i>
  </button>
</div>
