import {Component, Input} from '@angular/core';
import {TimedMessage} from './timed-message';

@Component({
  selector: 'app-timed-message',
  templateUrl: './timed-message.component.html',
  styleUrls: ['./timed-message.component.scss']
})
export class TimedMessageComponent {

  /**
   * A TimedMessage-object as 'configuration'.
   */
  @Input() alert: TimedMessage;

  @Input() html: boolean = false;
}
