import {Alert} from '../../enum/alert.enum';
import {trustedHTMLFromString} from "@angular/material/icon/trusted-types";

/**
 * Object to be displayed in the TimedMessage-component.
 */
export class TimedMessage {

  /**
   * The message to be displayed.
   */
  private message: string;

  /**
   * Defines the color the Message should be displayed in.
   */
  private alertType: Alert;

  /**
   * Weather the component should be visible or not.
   */
  private visible = false;

  /**
   * The time (in ms) the message should be visible.
   */
  private timer: number;

  /**
   * A timeout-token to be cleared if the message shell be hidden before the timer ended.
   */
  private timeout: any;

  /**
   * To show a message.
   * @param message
   * The message to be shown.
   * @param alertType
   * The class to be appended.
   * @param timer
   * The time the message should be displayed.
   */
  public show(message: string, alertType: Alert = Alert.info, timer = 3000) {
    this.message = message;
    this.alertType = alertType;
    this.timer = timer;
    this.hide();
    this.visible = true;
    this.timeout = setTimeout(
      () => {
        this.hide();
      },
      this.timer
    );
  }

  /**
   * Hides the message. (Sets this.visible to false and clears this.timout.)
   */
  public hide() {
    this.visible = false;
    clearTimeout(this.timeout);
  }

  /**
   * Returns this.message.
   */
  public getMessage(): string {
    return this.message;
  }

  /**
   * Returns this.alertType.
   */
  public getAlertType(): string {
    return this.alertType;
  }

  /**
   * Returns this.visible.
   */
  public isVisible(): boolean {
    return this.visible;
  }
}
