export class Password {

  /**
   * The value of this password.
   */
  private password: string;

  /**
   * Weather this password is safe or not.
   */
  private safe: boolean;

  /**
   * Key figure of the safety of this password. (Range from 0 for not safe at all to 100 for safe.)
   */
  private complexity: number;

  /**
   * Single regular expressions to verify the necessary steps to create a secure password.
   */
  private regExps: RegExp[] = [
    /\d+/,
    /[A-Z]+/,
    /[a-z]+/,
    /[!"§$%&/()=?#+*~\\<>|@€]+/,
    /.{8,}/
  ];

  /**
   * Constructor
   * @param password
   * A optional password to be set. Else the password is set ''.
   */
  constructor(password?: string) {
    if (password !== undefined) {
      this.setPassword(password);
    } else {
      this.setPassword('');
    }
  }

  /**
   * Sets this.password and updates this.complexity and this.safe(ty).
   * @param txt
   * The new password-value.
   */
  public setPassword(txt: string) {
    this.password = txt;
    this.setComplexity();
    this.safe = this.complexity === 100;
  }

  /**
   * Returns the password.
   */
  public getPassword(): string {
    return this.password;
  }

  /**
   * Returns this.safe.
   */
  public isSafe(): boolean {
    return this.safe;
  }

  /**
   * Returns this.complexity.
   */
  public getComplexity(): number {
    return this.complexity;
  }

  /**
   * Sets this.complexity according to the current password.
   */
  private setComplexity(): void {
    let complexityCounter = 0;
    for (let i = 0; i < this.regExps.length; i++) {
      if (this.password !== undefined && this.password !== null && this.password.match(this.regExps[i]) !== null) {
        complexityCounter++;
      }
    }
    if (complexityCounter <= 0) {
      this.complexity = 0;
    } else {
      this.complexity = complexityCounter * 100 / this.regExps.length;
    }
  }

}
