import {Pipe, PipeTransform} from '@angular/core';
import {TranslateServiceMock} from './translate.service.mock';

@Pipe({
  name: 'translateMock',
  pure: false
})
export class TranslatePipeMock implements PipeTransform {

  /**
   * Constructor
   * @param translate
   * TranslateService to translate phrases.
   */
  constructor(private translate: TranslateServiceMock) {
  }

  /**
   * Pipe-method.
   * @param value
   * The value to be translated.
   * @param args
   * Interface relict.
   */
  transform(value: string, args?: any): string {
    return this.translate.translate(value);
  }

}
