import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  RequestHeaders: any = {
    'Content-Type': 'application/json;charset="utf-8"',
    'Accept': 'application/json',
    'rtkApiKey': ApiService.getCurrentApiToken()
  };

  constructor(private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('accessToken');
    if (token !== '' && token !== null) {
      this.RequestHeaders.Authorization = 'Bearer ' + token;
    }
    req = req.clone({
      setHeaders: this.RequestHeaders
    });
    return next.handle(req).pipe(tap({
      next:(event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
      }, error:(err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 403 || err.status === 401) {
            localStorage.removeItem('accessToken');
            location.reload();
            throw new Error(err.statusText);
          }
          throw new HttpErrorResponse({error:err});
        }
      }}));
  }
}
