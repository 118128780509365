import {Component} from '@angular/core';
import {Location} from '@angular/common';
import {InvoiceService} from '../../dependencies/invoice.service';
import {TimedMessage} from '../../dependencies/gui-elements/timed-message/timed-message';
import {TranslateService} from '../../dependencies/translation/translate.service';
import {Alert} from '../../dependencies/enum/alert.enum';
import {Router} from '@angular/router';

@Component({
  selector: 'app-password-forgotten',
  templateUrl: './password-forgotten.component.html',
  styleUrls: ['./password-forgotten.component.scss']
})
export class PasswordForgottenComponent {

  public customerID: any = '';

  public timedMessage: TimedMessage = new TimedMessage();

  public success = false;

  public timeLeft = 15;

  public interval;

  constructor(private location: Location, private service: InvoiceService, private translate: TranslateService, private router: Router) {
    if (this.router.getCurrentNavigation()?.extras.state) {
      this.customerID = this.router.getCurrentNavigation()?.extras.state.id;
    }
  }

  public back() {
    this.location.back();
  }

  public resetPassword(event?: KeyboardEvent) {
    if (event === undefined || event.key === 'Enter') {
      this.service.resetPasswordMail(Number(this.customerID)).subscribe( data => {
        if (data === true) {
          this.success = true;
          if (this.success) {
            this.startTimer();
          }
        } else {
          this.timedMessage.show(
            this.translate.translate('errorWhileResetingPassword'), Alert.warning, 8000);
          this.success = false;
        }
      }, () => {
        this.timedMessage.show(
          this.translate.translate('errorWhileResetingPassword'), Alert.danger, 8000
        );
        this.success = false;
      });
    }
  }

  public startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.router.navigateByUrl('/Login');
        clearInterval(this.interval);
      }
    }, 1000);
  }

}
