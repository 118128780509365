<div class="container padding-top">
  <app-timed-message [alert]="timedMessage"></app-timed-message>
  <div class="card">
    <div class="card-header text-center">
      <h2>{{'title' | translate}}</h2>
    </div>
    <div class="card-body">
    <div class="p-4 main-content">
      <div class="card card-container login-container">
        <div class="d-flex justify-content-center mt-3">
          <div class="logo"></div>
        </div>
        <div>
          <h4 class="text-center mt-1">Code eingeben</h4>
          <span *ngIf="this.method === 1" class="code-text justify-content-center mt-1 mb-1">Wir haben einen 6-stelligen Code an {{ this.authProfile.mail.substring(0,5) + 'XXXXXXXXX' }} gesendet.</span>
          <span *ngIf="this.method === 1" class="send-new-code mt-1" (click)="getProfile()">Erneut senden</span>
          <span *ngIf="this.method === 2" class="code-text justify-content-center mt-1 mb-1">Bitte entnehmen Sie Ihrer Authenticator-App den 6-stelligen Code.</span>
        </div>
        <fieldset class="number-code">
          <div class='d-flex' (input)='checkInputs()' (paste)="onPaste($event)">
            <input name="code1" type='number' class="code-input" required maxlength="1" #code1Input/>
            <input name="code2" type='number' class="code-input" required maxlength='1' #code2Input/>
            <input name="code3" type='number' class="code-input" required maxlength='1' #code3Input/>
            <input name="code4" type='number' class="code-input" required maxlength='1' #code4Input/>
            <input name="code5" type='number' class="code-input" required maxlength='1' #code5Input/>
            <input name="code6" type='number' class="code-input" required maxlength='1' (input)="submitFormOnCode6()" #code6Input />
          </div>
        </fieldset>
        <div class="d-flex mb-3 justify-content-center">
          <button class="mt-3 btn btn-secondary mr-3" (click)="back2login()">Abbrechen</button>
          <button class="mt-3 btn btn-secondary" [disabled]="!res" (click)="sendCode()">Weiter</button>
        </div>
      </div>
    </div>
    </div>
  </div>
</div>
