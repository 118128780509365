import {Injectable} from '@angular/core';
import {SearchAttribute} from './search-attribute';
import {SearchObject} from './search-object';

/**
 * this service is injectable
 */
@Injectable()
/**
 * service for advanced search gui component
 */
export class AdvancedSearchService {
  /**
   * attribute list
   */
  private attributeList: Array<SearchAttribute>;

  /**
   * constructor - resets attribute list
   */
  constructor() {
    this.attributeList = [];
  }

  /**
   * search string
   */
  private _searchString: string;
  /**
   * getter for search string
   */
  get searchString(): string {
    return this._searchString;
  }

  /**
   * setter for search string
   * @param value string
   */
  set searchString(value: string) {
    this._searchString = value;
  }

  /**
   * last start position
   */
  private _lastStartPosition: number;
  /**
   * our analyze storage
   */
  public searchAnalyze = [];

  /**
   * getter for last start position
   */
  get lastStartPosition(): number {
    return this._lastStartPosition;
  }

  /**
   * setter for last start position
   * @param value number
   */
  set lastStartPosition(value: number) {
    this._lastStartPosition = value;
  }

  /**
   * set matches
   * @param matches array
   */
  public setMatches(matches: Array<SearchAttribute>) {
    this.attributeList = matches;
  }

  /**
   * get found matches for attribute search in the right order.
   */
  public getMatches(): SearchAttribute[] {
    const keys = this.attributeList.filter(elem => elem.attributeType === 'keyword');
    if (keys.length < 1) {
      // Only fields are in the attributeList. This is the case when the user selected / finished typing a keyword.
      return this.attributeList;
    } else {
      const result = [];
      keys.forEach( key => {
        result.push(key);
        result.push(...this.getFieldMatches(key.attributeName));
      });
      return result;
    }
  }

  /**
   * get matches for fields with the specified parent only.
   * @param parent string
   */
  public getFieldMatches(parent: string): SearchAttribute[] {
    return this.attributeList.filter(elem => elem.attributeType === 'field' && elem.attributeParent === parent);
  }

  /**
   * weather the list contains keywords.
   */
  public matchesContainKeys(): boolean {
    return this.attributeList.filter(elem => elem.attributeType === 'keyword').length > 0;
  }

  /**
   * reset service
   */
  public reset() {
    this.setMatches([]);
  }


  /**
   * Transforms an Array of SearchObjects to an array of objects as following:
   * kind: The keyword the string is specified for
   * in: an array of strings where the keyword shell be in.
   * out: an array of strings where the keyword shell be out.
   * @param objects
   * An array of SeachObjects to be transformed
   */
  public transformSearchObjects(objects: SearchObject[]): any[] {
    const result = [];
    let kind = '';
    for (let i = 0; i < objects.length; i++) {
      if (objects[i].objectType === 'keyword') {
        kind = objects[i].objectPlainString;
      } else {
        let e = 0;
        while (e < result.length && result[e].type !== kind) {
          e++;
        }
        if (result[e] === undefined) {
          result.push(
            {
              type: kind,
              in: [],
              out: []
            }
          );
        }
        if (objects[i].objectOperant === 'IN') {
          result[e].in.push(objects[i].objectPlainString);
        } else {
          result[e].out.push(objects[i].objectPlainString);
        }
      }
    }
    return result;
  }
}
