import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private static localApi = 'http://localhost:8100';

  private static testApi = 'https://gate.kairos-rtg.de/billing-portal-demo';

  private static liveApi = 'https://gate.kairos-rtg.de/billing-portal';

  private static testToken = '5d1b3d36-62e6-42ae-86bf-9ba3f94f6d81';

  private static liveToken = '5da96019-cbdc-479b-a5eb-f07c27fc6a1f';

  /**
   * Returns the current Api Url.
   */
  public static getCurrentApi(): string {
    if (environment.branch === 'produktiv') {
      return ApiService.liveApi;
    } else if (environment.branch === 'abnahme') {
      return ApiService.testApi;
    }
    return ApiService.localApi;
  }

  /**
   * Returns the current API Token
   */
  public static getCurrentApiToken(): string {
    if (environment.branch === 'produktiv') {
      return this.liveToken;
    } else if (environment.branch === 'abnahme') {
      return this.testToken;
    }
    return '';
  }

  /**
   * Returns the Local Api Url
   */
  public static getLocalApi(): string {
    return ApiService.localApi;
  }

  /**
   * Returns the Test Api Url
   */
  public static getTestApi(): string {
    return ApiService.testApi;
  }

  /**
   * Returns the Live Api Url
   */
  public static getLiveApi(): string {
    return ApiService.liveApi;
  }

  /**
   * Returns the Local Api Token
   */
  public static getLocalToken(): string {
    return '';
  }

  /**
   * Returns the Test Api Token
   */
  public static getTestToken(): string {
    return ApiService.testToken;
  }

  /**
   * Returns the Live Api Token
   */
  public static getLiveToken(): string {
    return ApiService.liveToken;
  }
}
