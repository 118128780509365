export class TranslateMockData {
  public static dictionary = [
    [
      'language',
      'title',
      'password',
      'emailAddress',
      'login',
      'forgotPassword',
      'noBillingsFound',
      'Travelagency',
      'date',
      'billingNumber',
      'firstPage'
    ],
    [
      'English',
      'rtg-Invoice Portal',
      'password',
      'email-address',
      'login',
      'Forgot password?',
      'There were no billings to be displayed.',
      'Travelagency',
      'date',
      'billing number',
      'first page'
    ],
    [
      'Deutsch',
      'rtg-Rechnungsportal',
      'Passwort',
      'Emal-Adresse',
      'Login',
      'Passwort vergessen?',
      'Es wurden keine Rechnungen zu der Anfrage gefunden.',
      'Reisebüro',
      'Datum',
      'Rechnungsnummer',
      'Erste Seite'
    ]
  ];
}
