/**
 * Categories to be holt by an order-object.
 */
export class OrderElement {

  /**
   * Constructor
   * @param orderElement
   * The orderElement this object shell define.
   * @param ascending
   * Weathe the order shell be ascending or descending.
   */
  constructor(readonly orderElement: number, private ascending: boolean) {}

  /**
   * Compares this orderElement to another orderElement.
   * @param orderElement
   * The orderElement to be compared to.
   */
  public isTypeOf(orderElement: number): boolean {
    return this.orderElement === orderElement;
  }

  /**
   * Toggles the ascending-attribute.
   */
  public toggle() {
    this.ascending = !this.ascending;
  }

  /**
   * returns this orderElement-attribute.
   */
  public getOrderElement(): number {
    return this.orderElement;
  }

  /**
   * returns this ascending-attribute.
   */
  public getAscending(): boolean {
    return this.ascending;
  }

}
