import * as FileSaver from 'file-saver';

/**
 * Objects of Billings
 * */

export class Billing {
  public Count: number;
  public Row: string;
  public Gutschriften: number;
  public Rechnungen: number;
  public ID: string;
  public Rechnungsnummer: number;
  public Rechnungsdatum: string;
  public Kundennummer: string;
  public TravelAgency: string;
  public PDF: string;
  public UserRead: string;
  public UserInformed: string;
  public SAPMandant: string;
  public DocumentID: number;
  public GesamtSumme: string;
  public GesamtSummeBrutto: string;
  public Rechnungstyp: string;
  public Mandant: string;
  public Loading: boolean;

  constructor(
    Count: number,
    Row: string,
    Gutschriften: number,
    Rechnungen: number,
    ID: string,
    Rechnungsnummer: number,
    Rechnungsdatum: string,
    Kundennummer: string,
    TravelAgency: string,
    PDF: string,
    UserRead: string,
    UserInformed: string,
    SAPMandant: string,
    DocumentID: number,
    GesamtSumme: string,
    GesamtSummeBrutto: string,
    Rechnungstyp: string,
    Mandant: string,
    Loading: boolean
  ) {
    this.Count = Count;
    this.Row = Row;
    this.Gutschriften = Gutschriften;
    this.Rechnungen = Rechnungen;
    this.ID = ID;
    this.Rechnungsnummer = Rechnungsnummer;
    this.Rechnungsdatum = Rechnungsdatum;
    this.Kundennummer = Kundennummer;
    this.TravelAgency = TravelAgency;
    this.PDF = PDF;
    this.UserRead = UserRead;
    this.UserInformed = UserInformed;
    this.SAPMandant = SAPMandant;
    this.DocumentID = DocumentID;
    this.GesamtSumme = GesamtSumme;
    this.GesamtSummeBrutto = GesamtSummeBrutto;
    this.Rechnungstyp = Rechnungstyp;
    this.Mandant = Mandant;
    this.Loading = Loading;
  }
}

/**
 * Objects of Billings
 * */
/**
 * Object billing details
 */
export class BillingDetails {
  /**
   * ID
   */
  public ID: number;
  /**
   * Rechnungsnummer
   */
  public Rechnungsnummer: number;
  /**
   * Rechnungsdatum
   */
  public Rechnungsdatum: string;
  /**
   * Menge
   */
  public Menge: number;
  /**
   * Beschreibung
   */
  public Beschreibung: string;
  /**
   * LeistungAb
   */
  public LeistungAb: string;
  /**
   * LeistungBis
   */
  public LeistungBis: string;
  /**
   * Netto
   */
  public Netto: string;
  /**
   * Gesamtnetto
   */
  public GesamtNetto: string;
  /**
   * Summe Netto
   */
  public SummeNetto: string;
  /**
   * DocumentID
   */
  public DocumentID: number;
  /**
   * AnhangID
   */
  public AnhangID: number;
  /**
   * UserInformed
   */
  public UserInformed: boolean;

  /**
   * Constructor
   * @param ID as number
   * @param Rechnungsnummer as number
   * @param Rechnungsdatum as string
   * @param Menge as number
   * @param Beschreibung as string
   * @param LeistungAb as string
   * @param LeistungBis as string
   * @param Netto as string
   * @param GesamtNetto as string
   * @param SummeNetto as string
   * @param DocumentID as number
   * @param AnhangID as number
   * @param UserInformed as boolean
   */
  constructor(
    ID: number,
    Rechnungsnummer: number,
    Rechnungsdatum: string,
    Menge: number,
    Beschreibung: string,
    LeistungAb: string,
    LeistungBis: string,
    Netto: string,
    GesamtNetto: string,
    SummeNetto: string,
    DocumentID: number,
    AnhangID: number,
    UserInformed: boolean
  ) {
    this.ID = ID;
    this.Rechnungsnummer = Rechnungsnummer;
    this.Rechnungsdatum = Rechnungsdatum;
    this.Menge = Menge;
    this.Beschreibung = Beschreibung;
    this.LeistungAb = LeistungAb;
    this.LeistungBis = LeistungBis;
    this.Netto = Netto;
    this.GesamtNetto = GesamtNetto;
    this.SummeNetto = SummeNetto;
    this.DocumentID = DocumentID;
    this.AnhangID = AnhangID;
    this.UserInformed = UserInformed;
  }
}

/**
 * class schema for file downloads
 */
export class FileDownload {
  /**
   * File name to send to browser
   */
  public FileName: string;
  /**
   * base 64 content
   */
  public FileContent: string;
  /**
   * file as blob
   */
  public File: Blob;
  /**
   * ContentType to send to browser
   */
  public ContentType: string;
  /**
   *
   */
  public _isScalar: false;

  public constructor(FileName: string, FileContent: string, ContentType: string) {
    this.createFile(FileName, FileContent, ContentType);
  }

  public createFile(FileName: string, FileContent: string, ContentType: string) {
    this.FileName = FileName;
    this.FileContent = FileContent;
    this.File = this.dataURItoBlob(FileContent, ContentType);
    this.ContentType = ContentType;
  }

  public openInWindow() {
    FileSaver.saveAs(this.File, this.FileName);
  }

  private dataURItoBlob(FileContent, ContentType) {
    const byteString = atob(FileContent);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], {type: ContentType});
  }
}

export class EmailToken {
  public EmailHash: string;
  public NewEmail: string;

  constructor(EmailHash: string, NewEmail: string) {
    this.EmailHash = EmailHash;
    this.NewEmail = NewEmail;
  }
}

export class Permissions {
  public AccessToBillingPortal: boolean;
  public Permission: boolean;
  public User: boolean;
  public Key: string;

  constructor(AccessToBillingPortal: boolean, Permission: boolean, User: boolean, Key: string) {
    this.AccessToBillingPortal = AccessToBillingPortal;
    this.Permission = Permission;
    this.User = User;
    this.Key = Key;
  }
}

export class AuthProfile {
  public prefMethod: number;
  public mail: string;
  public qrCode: string;
  public skipCounter: number;

  /**
   * Constructor
   * @param prefMethod
   * @param mail
   * @param qrCode
   * @param skipCounter
   */
  constructor(prefMethod: number, mail: string, qrCode: string, skipCounter: number) {
    this.prefMethod = prefMethod;
    this.mail = mail;
    this.qrCode = qrCode;
    this.skipCounter = skipCounter;
  }
}
