import {Directive, OnInit} from '@angular/core';
import {Order} from './list/objects/order';
import {InvoiceService} from '../dependencies/invoice.service';
import {Router} from '@angular/router';
import {SearchObject} from '../dependencies/gui-elements/advanced-search/search-object';
import {Limit} from '../dependencies/gui-elements/paging-footer/limit';
import {TimedMessage} from '../dependencies/gui-elements/timed-message/timed-message';
import {TranslateService} from '../dependencies/translation/translate.service';
import {AdvancedSearchService} from "../dependencies/gui-elements/advanced-search/advanced-search.service";

@Directive()
export abstract class BillingElement implements OnInit {
  /**
   * limits to selected of items per page
   */
  public itemLimits: number[] = [25, 50, 75, 100];

  /**
   * the total count of items potentially selected with the current search-parameters
   */
  public totalCount: number;

  /**
   * the current limit set for items per page
   */
  public limit: number;

  /**
   * the current page
   */
  public page: number;

  /**
   * uncalculated number of pages
   */
  public uPage: number;

  /**
   * saves the latest confirmed search-event to apply it when the user changes the order or page.
   */
  public latestSearch: SearchObject[];

  /**
   * order object
   */
  public orderObject: object;

  /**
   * order-object to handle sorting-logic
   */
  public order: Order;

  /**
   * TimedMessage-Object to display Notifications when necessary
   */
  public timedMessage: TimedMessage = new TimedMessage();

  /**
   * constructor function
   * @param service InvoiceService
   * @param router Router
   * @param translation TranslateService
   */
  public constructor(
    public service: InvoiceService,
    public router: Router,
    public translation: TranslateService
  ) {
  }

  /**
   * initialization of all data-bound properties
   */
  public ngOnInit() {
    this.order = new Order(this.orderObject);
    this.page = 0;
    this.limit = this.itemLimits[0];
  }

  /**
   * sets the input-parameters for the paging-footer-GUI
   * @param totalCount number
   * @param limit number
   */
  public pageControl(totalCount: number, limit: number) {
    this.totalCount = totalCount;
    this.limit = limit;
  }

  /**
   * changing the page
   * @param page number
   */
  public pageChange(page: number) {
    this.uPage = page;
    this.page = (page - 1) * this.limit;
  }


  /**
   * setting the limit
   * @param event limit
   */
  public setLimit(event: Limit) {
    this.limit = event.limit;
    this.pageChange(event.page);
  }

  /**
   * ordering selected items in the table
   // tslint:disable-next-line:no-redundant-jsdoc
   * @param element number
   */
  orderBy(element: number) {
    this.order.orderBy(element);
  }

  /**
   * search for filtered input on submit
   * @param event SearchObject
   */
  public searchSubmit(event: SearchObject[]) {
    this.page = 0;
    this.latestSearch = event;
  }
}

