<div class="container padding-top">
  <div class="card">
    <div class="card-header text-center">
      <h2>{{'title' | translate}}</h2>
    </div>
    <div class="card-body">
      <div class="d-flex justify-content-center mb-2">
        <div class="btn-group" >
          <label class="btn border group-btn {{ type === 'appAuth' ? 'btn-secondary active' : '' }}" (click)="checkType('appAuth')">
            <i class="fa-solid fa-mobile-button"></i><span class="ml-1"> {{ 'App Authenticator' | translate }}</span>
          </label>
          <label class="btn border group-btn {{ type === 'mailAuth' ? 'btn-secondary active' : '' }}" (click)="checkType('mailAuth')">
            <i class="fa-solid fa-envelope"></i><span class="ml-1"> {{ 'E-Mail Authenticator' | translate }}</span>
          </label>
        </div>
      </div>
      <!-- Google -->
      <div *ngIf="type === 'appAuth'">
        <div>
          <div class="d-flex flex-column">
            <div class="d-flex border justify-content-center app">
              <div class="logo"></div>
            </div>
            <div class="d-flex justify-content-start mt-3">
              <h3>Authenticator einrichten</h3>
            </div>
            <div class="d-flex justify-content-start">
              <ul>
                <li>Verwenden Sie Ihre bestehende Authenticator App <b>oder</b></li>
                <li>Laden Sie die Authenticator App Ihrer Wahl im <b>Google Play Store</b> oder <b>App Store</b> herunter.</li>
                <li>Wählen Sie in der App die Option <b>Konto einrichten</b>.</li>
                <li>Wählen Sie <b>Barcode scannen</b> aus.</li>
              </ul>
            </div>
            <div class="d-flex justify-content-center mt-3">
              <img *ngIf="qr" style="max-width: 150px" [src]="qr" alt="QrCode" />
            </div>
            <p *ngIf="qr" class="text-danger text-center mt-3">
              <i class="fa-solid fa-circle-exclamation mr-1"></i
              >{{
              "Bitte scannen Sie zuerst den QR Code mit Ihrem Smartphone/Tablet, bevor Sie fortfahren."
              }}
            </p>
          </div>

          <div class="d-flex justify-content-end mt-1">
            <button class="mb-3 mr-4 btn btn-secondary"
                    (click)="back2login()"
            >
              {{ "Abbrechen" }}
            </button>
            <button class="mb-3 btn btn-secondary"
                    (click)="initiateQrAuth(true)"
            >
              {{ "Weiter" }}
            </button>
          </div>
        </div>
      </div>
      <!-- Mail -->
      <div *ngIf="type === 'mailAuth'">
        <h3>Unsere Empfehlung</h3>
        <i class="fa-solid fa-circle-exclamation text-danger mr-1"></i>
        <span class="text-danger">Wir weisen darauf hin das die Nutzung einer Authenticator App wesentlich sicherer ist!</span>
        <div>
          <div class="d-flex mt-4 mb-4">
            <input
              class="form-control mb-2"
              type="text"
              [(ngModel)]="email"
              placeholder="E-Mail"
              disabled
            >
          </div>
          <div class="d-flex justify-content-center">
            <button class="mb-3 btn btn-secondary"
                    (click)="initiateMailAuth()"
                    [disabled]="
                (emailFormControl.hasError('email') &&
                  !emailFormControl.hasError('required')) ||
                email === undefined ||
                email?.length === 0
              "
            >
              {{ "Code senden" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
