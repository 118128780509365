<div class="container margin-top">
  <div class="card">
    <div class="card-header">
      <h3>{{'resetPassword' | translate}}</h3>
    </div>
    <div class="card-body" *ngIf="!success">
      <app-timed-message [alert]="timedMessage"></app-timed-message>

      <!-- bigger than 767px -->
      <div class="mb-2 d-none d-md-block">
        <div class="input-group">
          <input type="number" id="emailBig" [ngClass]="{'is-invalid': !customerID, 'is-valid': customerID}"
                 class="form-control"
                 [(ngModel)]="customerID" (keyup)="resetPassword($event)">
          <label for="emailBig"></label>
          <div class="input-group-append">
            <button class="btn btn-success" (click)="resetPassword()" [disabled]="!customerID">
              {{'resetPassword' | translate}}
            </button>
          </div>
          <div class="invalid-feedback">
            {{'noValidCustomerID' | translate}}
          </div>
        </div>
      </div>
      <!-- smaller than 768px -->
      <div class="mb-2 d-block d-md-none">
        <div class="input-group mb-2">
          <input type="number" id="emailSmall" [ngClass]="{'is-invalid': !customerID, 'is-valid': customerID}"
                 class="form-control"
                 [(ngModel)]="customerID" (keyup)="resetPassword($event)">
          <label for="emailSmall"></label>
          <div class="invalid-feedback">
            {{'noValidCustomerID' | translate}}
          </div>
        </div>
        <button class="btn btn-success" (click)="resetPassword()" [disabled]="!customerID">
          {{'resetPassword' | translate}}
        </button>
      </div>
      <button class="btn btn-secondary" (click)="back()">
        {{'back' | translate}}
      </button>
    </div>
    <div class="card-body" *ngIf="success">
      <span>{{ 'mailToResetYourPassword' | translate }}</span>
      <br><br>
      <span>{{ 'PasswordForgot1' | translate }} <b>{{ this.timeLeft }}</b> {{ 'PasswordForgot2' | translate }}</span>
    </div>
  </div>
</div>
