import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {AdvancedSearchService} from './advanced-search.service';
import {SearchAttribute} from './search-attribute';
import {SearchObject} from './search-object';
import {Router} from '@angular/router';
import {AdvancedSearchMockData} from './advanced-search-mock-data';
import {TranslateService} from '../../translation/translate.service';

/**
 * gui component for advanced search capabilities
 */
@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
/**
 * component class
 * this controls all search mechanism
 */
export class AdvancedSearchComponent implements OnInit, OnDestroy {

  /**
   * search text
   */
  public searchText = '';

  /**
   * reset search text
   */
  @Output() searchTextReset: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * the event emitter to access search object
   */
  @Output() searchSubmit = new EventEmitter<SearchObject[]>();
  /**
   * the event emitter to access search string and search object
   */
  @Output() searchSubmitWithInput = new EventEmitter<any>();

  /**
   * we have to track arrow key location in suggest list
   * is used to control up and down events
   */
  public arrowKeyLocation = -1;
  /**
   * result of current input is analyzed and stored here
   */
  public searchAnalyze: Array<SearchObject> = [];
  /**
   * reference to input field
   */
  @ViewChild('inputField') inputField;

  /**
   * input parameter to receive search attributes for autocomplete
   */
  @Input() public searchAttributes: Array<SearchAttribute>;
  /**
   * input parameter to preset form field value
   */
  @Input() public searchString: string;

  /**
   * placeholder of search field
   */
  @Input() public placehold = 'Suche';
  /**
   * differs if more than
   * one search item is used
   */
  @Input() public active = 1;

  /**
   * Due to presentation errors:
   * words will not be underlined if specified false
   */
  @Input() public enableUnderline = true;

  /**
   * Constructor
   * @param searchService AdvancedSearchService
   * @param router Router
   * @param translate TranslateService
   */
  constructor(
    public searchService: AdvancedSearchService,
    private router: Router,
    public translate: TranslateService
  ) {
  }

  /**
   * on init does nothing
   * comes with sample attributes if none is set
   */
  ngOnInit() {
    if (this.searchAttributes === undefined) {
      this.searchAttributes = AdvancedSearchMockData.dummySearchAttributes;
    }
    this.doSearchAnalyze();
  }

  /**
   * Triggered when the Component vanishes from the view.
   * In this case it is used to prevent interferences in case of multiple advanced-search-components.
   */
  ngOnDestroy() {
    this.searchService.reset();
  }

  public onInput(event: KeyboardEvent) {
    switch (event.code) {
      case 'ArrowUp':
        this.arrowKeyLocation = (this.arrowKeyLocation > 0) ? this.arrowKeyLocation - 1 : this.arrowKeyLocation;
        break;
      case 'ArrowDown':
        const matchesLength = this.searchService.getMatches().length;
        this.arrowKeyLocation = (this.arrowKeyLocation < matchesLength - 1) ? this.arrowKeyLocation + 1 : this.arrowKeyLocation;
        break;
      case 'Space':
        if (event.ctrlKey === true) {
          this.analyzeQuery();
        }
        break;
      case 'Tab':
        event.preventDefault();
        this.autoComplete(event);
        break;
      case 'Enter':
        this.submitOrSelect(event);
        break;
      case 'NumpadEnter':
        this.submitOrSelect(event);
        break;
      case 'Escape':
        this.cancelSuggestions(event);
        break;
      case 'Delete':
        if (event.ctrlKey === true) {
          this.clearAll();
        }
        break;
      default:
    }
  }

  /**
   * reset some stuff on esc press
   * @param event event
   */
  public cancelSuggestions(event) {
    this.arrowKeyLocation = -1;
    this.searchService.reset();
  }

  /**
   * Enter Event, submits form or selects value
   * @param event event
   */
  public submitOrSelect(event) {
    let oneActive = false;
    const options = this.searchService.getMatches();
    if (Array.isArray(options) && options.length > 0) {
      if (options.length > 0) {
        const searchObject = this.searchAnalyze[this.searchAnalyze.length - 1];
        for (let i = 0; i < options.length; i++) {
          if (i === this.arrowKeyLocation) {
            this.searchText = this.searchText.substr(
              0,
              searchObject.objectPosition) + this.getReplacement(searchObject, options[i].attributeName, options[i].attributeType);
            this.doSearchAnalyze();
            this.searchService.setMatches([]);
            oneActive = true;
          }
        }
        this.arrowKeyLocation = -1;
      }
    }
    if (oneActive === false) {
      this.submit();
    }
  }

  /**
   * tab Event, selects first value from option list if provided
   * @param event event
   */
  public autoComplete(event) {
    const options = this.searchService.getMatches();
    const searchObject = this.searchAnalyze[this.searchAnalyze.length - 1];
    this.searchText = this.searchText.substr(
      0,
      searchObject.objectPosition) + this.getReplacement(searchObject, options[0].attributeName, options[0].attributeType);
    this.arrowKeyLocation = -1;
    this.searchService.setMatches([]);
    this.doSearchAnalyze();
  }

  /**
   * click event for suggestions
   * @param match match
   */
  public clickSelect(match) {
    const searchObject = this.searchAnalyze[this.searchAnalyze.length - 1];
    this.searchText = this.searchText.substr(
      0,
      searchObject.objectPosition) + this.getReplacement(searchObject, match.attributeName, match.attributeType);
    this.arrowKeyLocation = -1;
    this.searchService.setMatches([]);
    this.doSearchAnalyze();
    this.inputField.nativeElement.focus();
  }

  /**
   * fetch all user keys.
   */
  public analyzeQuery() {
    this.doSearchAnalyze();
    if (this.searchAnalyze.length > 0) {
      let suggestionList: SearchAttribute[] = this.searchAttributes;
      const lastObject = this.searchAnalyze[this.searchAnalyze.length - 1];
      const twoLastObject = this.searchAnalyze.length > 1 ? this.searchAnalyze[this.searchAnalyze.length - 2] : new SearchObject();

      // only show fields fitting the last keyword.
      const latestKeyword = this.searchAnalyze.filter(obj => obj.objectType === 'keyword').pop();
      if (latestKeyword !== undefined && latestKeyword !== null) {
        suggestionList = suggestionList.filter(
          att => att.attributeType === 'keyword' || att.attributeParent === latestKeyword.objectPlainString
        );
      } else {
        // no keyword -> only show keywords as suggestion.
        suggestionList = suggestionList.filter(
          att => att.attributeType === 'keyword'
        );
      }

      if (lastObject.objectString !== '') {

        if (lastObject.objectType === null && twoLastObject.objectType === null) {
          suggestionList = suggestionList.filter(att => att.attributeParent === '');
        } else {
          suggestionList = this.filterSuggestionList(twoLastObject, suggestionList);
        }

        if (lastObject.objectPlainString !== undefined && lastObject.objectPlainString !== null && lastObject.objectPlainString !== '') {
          // remove + and - sign from the first position of the string.
          const lookUpString = lastObject.objectPlainString.replace(/^[+,-]/, '');
          this.arrowKeyLocation = -1;
          this.searchService.setMatches(
            suggestionList.filter(
              s => s.attributeName.toLowerCase().search(lookUpString.toLowerCase()) !== -1
            )
          );
        }
      } else {
        suggestionList = this.filterSuggestionList(twoLastObject, suggestionList);
        if (twoLastObject.objectType === null) {
          suggestionList = suggestionList.filter(att => att.attributeParent === '');
        }
        this.searchService.setMatches(suggestionList);
      }
    }
  }

  /**
   * returns a new suggestion list based on the provided one, only containing fitting values.
   * @param secondLastObject SearchObject
   * @param suggestionList SearchAttribute
   */
  private filterSuggestionList(secondLastObject: SearchObject, suggestionList: SearchAttribute[]): SearchAttribute[] {
    if (secondLastObject.objectType === 'keyword') {
      return suggestionList.filter(
        att => att.attributeParent === this.cleanString(secondLastObject.objectString)
      );
    }
    if (secondLastObject.objectType === 'field') {
      const helperList = suggestionList.filter(
        sl => sl.attributeName === this.cleanString(secondLastObject.objectString).split('%').join(' ')
      );
      const parent = helperList.length > 0 ? helperList[0].attributeParent : undefined;
      return suggestionList.filter(
        att => att.attributeParent === '' || att.attributeParent === parent
      );
    }
    return suggestionList;
  }

  /**
   * get replacement string
   * @param searchObject searchObject
   * @param value value
   * @param type type
   */
  public getReplacement(searchObject, value, type) {

    let replacement = '';

    switch (type) {
      case 'keyword': {
        replacement = value.trim() + ': ';
        break;
      }
      case 'field': {
        const valueString = '#' + value.trim().split(' ').join('%') + ' ';
        switch (searchObject.objectOperant) {
          case 'IN': {
            replacement = '+' + valueString;
            break;
          }
          case 'OUT': {
            replacement = '-' + valueString;
            break;
          }
          default: {
            replacement = '+' + valueString;
          }
            break;
        }
      }
        break;
      default: {
        replacement = value;
      }
    }
    return replacement;
  }

  private createCopyFromSearchAnalyze(): Array<SearchObject> {
    const searchAnalyzeCopy = [];
    for (let i = 0; i < this.searchAnalyze.length; i++) {
      const element = JSON.parse(JSON.stringify(this.searchAnalyze[i]));
      if (element.objectType === 'keyword') {
        element.objectString = element.objectString.substring(0, element.objectString.length - 1) + ':';
      }
      searchAnalyzeCopy.push(element);
    }
    return searchAnalyzeCopy;
  }

  /**
   * clears input field and resets searchObjects
   */
  public clearAll() {
    this.searchService.reset();
    this.searchText = '';
    this.doSearchAnalyze();
  }

  /**
   * analyze search string and build search objects
   */
  private doSearchAnalyze() {
    this.searchAnalyze = [];
    let searchArr = [];
    if (this.searchText !== undefined) {
      searchArr = this.searchText.split(' ');
    } else {
      searchArr = [''];
    }
    let positionCounter = 0;
    const fieldIdentifier = ['+#', '-#'];

    for (let i = 0; i < searchArr.length; i++) {
      if (searchArr[i] !== undefined) {
        let searchType = 'string';
        positionCounter = (positionCounter > 0) ? positionCounter + 2 : positionCounter;

        if (searchArr[i].charAt(searchArr[i].length - 1) === ':') {
          searchType = 'keyword';
        } else if (fieldIdentifier.find(sChar => sChar === searchArr[i].substring(0, 2))) {
          searchType = 'field';
        }
        this.searchAnalyze.push(
          new SearchObject(searchType, searchArr[i], positionCounter, positionCounter + searchArr[i].length - 1)
        );
        positionCounter += searchArr[i].length - 1;
      }
    }
  }

  /**
   * cleans chars from input used for search identifier
   * @param myString string
   */
  private cleanString(myString: string) {
    return myString.replace('+#', '').replace('-#', '').replace(':', '');
  }

  /**
   * everything necessary to submit the search.
   */
  public submit() {
    this.doSearchAnalyze();
    const searchAnalyzeCopy = this.createCopyFromSearchAnalyze();
    this.searchSubmit.emit(searchAnalyzeCopy.filter(att => att.objectString !== ''));
    this.searchSubmitWithInput.emit({
      searchTerm: this.searchAnalyze.filter(att => att.objectString !== ''),
      searchString: this.searchText
    });
  }
}
