import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './components/login/login.component';
import {NavigationComponent} from './components/navigation/navigation.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {APP_BASE_HREF, CommonModule, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {RouterModule} from '@angular/router';
import {ProfileComponent} from './components/profile/profile.component';
import {ListComponent} from './components/list/list.component';
import {TimedMessageComponent} from './dependencies/gui-elements/timed-message/timed-message.component';
import {TranslateService} from './dependencies/translation/translate.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {DetailComponent} from './components/detail/detail.component';
import {PasswordFormComponent} from './dependencies/gui-elements/password-form/password-form.component';
import {AuthModule} from 'ngx-auth';
import {LoginService} from './dependencies/authentication/login.service';
import {AuthHttpInterceptor} from './dependencies/authentication/auth-http-interceptor';
import {PasswordForgottenComponent} from './components/password-forgotten/password-forgotten.component';
import {ResetPasswordComponent} from './components/reset-password/reset-password.component';
import {AuthenticationModule} from './dependencies/authentication/authentication.module';
import {AdvancedSearchModule} from './dependencies/gui-elements/advanced-search/advanced-search.module';
import {OrderBadgeComponent} from './dependencies/order/order-badge/order-badge.component';
import {PagingFooterComponent} from './dependencies/gui-elements/paging-footer/paging-footer.component';
import {PagingInfoComponent} from './dependencies/gui-elements/paging-info/paging-info.component';
import { EmailChangeComponent } from './components/email-change/email-change.component';
import { OauthComponent } from './components/oauth/oauth.component';
import { AuthenticatorComponent } from './components/authenticator/authenticator.component';
import { AuthmethodComponent } from './components/authmethod/authmethod.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavigationComponent,
    ProfileComponent,
    ListComponent,
    TimedMessageComponent,
    DetailComponent,
    PasswordFormComponent,
    PasswordForgottenComponent,
    ResetPasswordComponent,
    OrderBadgeComponent,
    PagingFooterComponent,
    PagingInfoComponent,
    EmailChangeComponent,
    OauthComponent,
    AuthenticatorComponent,
    AuthmethodComponent
  ],
  providers: [
    TranslateService,
    LoginService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    AuthModule,
    AuthenticationModule,
    AdvancedSearchModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
