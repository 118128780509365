<div class="container margin-top border">
  <div class="table-responsive p-3">
  <app-timed-message [alert]="timedMessage"></app-timed-message>
  <table class="table table-striped table-sm-sm">
    <tr>
      <th class="border-0" colspan="2">{{'userProfile' | translate}}</th>
    </tr>
    <tr>
      <th>{{'name' | translate}}</th>
      <td>{{profile?.getName()}}</td>
    </tr>

    <tr>
      <th>{{'customerID' | translate}}</th>
      <td>{{profile?.getCustomerID()}}</td>
    </tr>
    <tr>
      <th>
        {{'language' | translate}}
      </th>
      <td>
        <select class="form-control" id="languageBig" (change)="setLanguage(currentLanguage)"
                [(ngModel)]="currentLanguage">
          <option *ngFor="let language of availableLanguages">
            {{language}}
          </option>
        </select>
        <label for="languageBig"></label>
      </td>
    </tr>
    <tr>
      <th>{{'email' | translate}}</th>
      <td>
        <div class="d-flex">
          <span *ngIf="!edit">{{ profile?.getEmail() }}</span>
          <button class="btn btn-success ml-auto" *ngIf="!edit" (click)="editMode()">Ändern</button>
        </div>
        <div class="input-group" *ngIf="edit">
          <input [ngClass]="{'is-invalid': !isValidEmail(), 'is-valid': isValidEmail()}" class="form-control"
                 id="emailBig"
                 type="text" [(ngModel)]="email" (keyup)="changeEmail($event)">
          <label for="emailBig"></label>
          <div class="input-group-append">
            <button class="btn btn-success" (click)="changeEmail()" [disabled]="!isValidEmail()">
              <i class="fa fa-save"></i>
            </button>
            <button class="btn btn-danger" (click)="editMode()">
              <i class="fas fa-times-circle"></i>
            </button>
          </div>
          <div class="invalid-feedback">
            {{'noValidEmail' | translate}}
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <th>
        {{'changePassword' | translate}}
      </th>
      <td>
        <app-password-form></app-password-form>
      </td>
    </tr>
  </table>
</div>
</div>
