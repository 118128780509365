/**
 * search attribute Schema
 * structure:
 *  attributeName: string -> name or value of attribute
 *  attributeType: string -> can be on of ( keyword, field, string )
 *  attributeParent: string -> name of parent attribute, used to define values for fields
 */

export class SearchAttribute {
  /**
   * name or value of attribute
   */
  attributeName: string;
  /**
   * type of attribute, can be keyword, field, string
   */
  attributeType: string;
  /**
   * name of parent attribute, used to define values for fields
   */
  attributeParent: string;

  /**
   * constructor
   * @param attributeName string
   * @param attributeType must be one of the following: 'keyword', 'field'
   */
  constructor(attributeName: string, attributeType: string, attributeParent: string) {
    this.attributeName = attributeName;
    this.attributeType = attributeType;
    this.attributeParent = attributeParent;
  }

  /**
   * implementation for DropdownStraightObject
   */
  public toString(): string {
    return (this.attributeType === 'field' ? '&nbsp;&nbsp;' : '') + this.attributeName;
  }
}
