import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {InvoiceService} from '../../dependencies/invoice.service';
import {EmailToken} from '../../dependencies/objects/bill';
import {Alert} from '../../dependencies/enum/alert.enum';
import {TranslateService} from '../../dependencies/translation/translate.service';
import {TimedMessage} from '../../dependencies/gui-elements/timed-message/timed-message';

@Component({
  selector: 'app-email-change',
  templateUrl: './email-change.component.html',
  styleUrls: ['./email-change.component.scss']
})
export class EmailChangeComponent implements OnInit {
  /**
   * email hash
   */
  public hash: string;

  /**
   * checks if token exists
   */
  public denied = false;

  /**
   * save new email
   */
  public newEmail: EmailToken;

  /**
   * TimedMessage-Object to display Notifications when necessary
   */
  public timedMessage: TimedMessage = new TimedMessage();

  /**
   * Constructor
   * @param router router
   * @param route route
   * @param service service
   * @param translation translation
   */
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public service: InvoiceService,
    public translation: TranslateService
  ) { }

  async ngOnInit() {
    if (this.route.snapshot.params.hash) {
      this.hash = this.route.snapshot.params.hash;
      await this.GetEmailToken();
      await this.ChangeEmail();
    }
    setTimeout(() => {
      this.back();
    }, 10000);
  }

  /**
   * gets email token with new email
   */
  public async GetEmailToken() {
    await this.service.getEmailToken(this.hash).toPromise().then(data => {
      this.newEmail = data;
      this.denied = !this.newEmail.EmailHash;
    }, () => {
      this.denied = true;
    });
  }

  /**
   * deletes email token
   */
  public async DeleteEmailToken() {
    await this.service.deleteEmailToken(this.hash).toPromise().then(() => {
      this.denied = !this.newEmail.EmailHash;
    });
  }

  /**
   * changes user email address
   */
  public async ChangeEmail() {
    this.service.changeEmail(this.newEmail.NewEmail, this.hash).subscribe(() => {
      this.denied = !this.newEmail.EmailHash;
      this.timedMessage.show(this.translation.translate('emailSuccessfullyChanged'), Alert.success);
    }, () => {},
      () => {
        this.DeleteEmailToken();
      });
  }

  /**
   * back to user profile
   */
  public back() {
    this.router.navigateByUrl('Billing/Profile');
  }

}
