/**
 * Profile-data to be displayed.
 */
export class Profile {

  /**
   * Constructor
   * @param CustomerID
   * Customer ID.
   * @param Email
   * Email Address.
   * @param TravelAgency
   * TravelAgency.
   */
  constructor(private CustomerID: string, private Email: string, private TravelAgency: string) {
  }

  /**
   * Returns the CustomerID of the profile.
   */
  public getCustomerID(): string {
    return this.CustomerID;
  }
  /**
   * Returns the email of the profile.
   */
  public getEmail(): string {
    return this.Email;
  }

  /**
   * Returns the name of the profile.
   */
  public getName(): string {
    return this.TravelAgency;
  }
}
