import {Component, OnInit} from '@angular/core';
import {Profile} from './profile';
import {InvoiceService} from '../../dependencies/invoice.service';
import {TimedMessage} from '../../dependencies/gui-elements/timed-message/timed-message';
import {TranslateService} from '../../dependencies/translation/translate.service';
import {Alert} from '../../dependencies/enum/alert.enum';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  /**
   * TimedMessage-Object to display Notifications when necessary
   */
  public timedMessage: TimedMessage = new TimedMessage();

  /**
   * A Profile-object to provide data to be displayed.
   */
  public profile: Profile;

  /**
   * Http-Model for the profile's email.
   */
  public email = '';

  /**
   * Array containing every available Language.
   */
  public availableLanguages: string[];

  /**
   * The currently set language.
   */
  public currentLanguage: string;

  /**
   * edit mode to change email
   */
  public edit = false;

  /**
   * Constructor
   * @param service
   * InvoiceService to handle API-calls and greater logic
   * @param translation
   * TranslateService to enable translations
   */
  constructor(private service: InvoiceService, private translation: TranslateService) {
  }

  /**
   * Gethers profile-data from the API an safes it to the local Profile-object.
   */
  ngOnInit() {
    this.service.getProfile().subscribe(
      data => this.profile = data,
      () => this.timedMessage.show(this.translation.translate('errorLoadingProfile'), Alert.danger),
      () => this.email = this.profile.getEmail()
    );
    this.availableLanguages = this.translation.getAvailableLanguages();
    this.currentLanguage = this.translation.getCurrentLanguage();
  }

  /**
   * To translate the content
   * @param language
   * the language the content should be translated to.
   */
  public setLanguage(language: string) {
    this.translation.setCurrentLanguage(language);
  }

  /**
   * function activates edit mode
   */
  public editMode() {
    this.edit = !this.edit;
  }

  /**
   * Checks weather the set email is valid or not.
   */
  public isValidEmail() {
    return InvoiceService.isValidEmail(this.email);
  }

  /**
   * API-Call to change the email-address to the current value.
   */
  public changeEmail(event?: KeyboardEvent) {
    if (event === undefined || event.key === 'Enter') {
      this.service.sendEmailConfirmation(this.email).subscribe(() => {
        this.timedMessage.show(this.translation.translate('emailSuccessfullyChanged'), Alert.success);
      }, () => {
        this.timedMessage.show(this.translation.translate('emailCouldNotBeSaved'), Alert.success);
      }, () => {
        this.editMode();
      });
    }
  }
}
