import {AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {LoginService} from "../../dependencies/authentication/login.service";
import {TimedMessage} from "../../dependencies/gui-elements/timed-message/timed-message";
import {Alert} from "../../dependencies/enum/alert.enum";
import {AuthProfile} from "../../dependencies/objects/bill";
import {TranslateService} from "../../dependencies/translation/translate.service";

@Component({
  selector: 'app-authenticator',
  templateUrl: './authenticator.component.html',
  styleUrls: ['./authenticator.component.scss']
})
export class AuthenticatorComponent implements OnInit, AfterViewInit {

  /**
   * Verification code inputs
   */
  public inputs: NodeListOf<HTMLInputElement>;

  authProfile: AuthProfile;

  method: Number = 0;
  code: String;
  message: string;
  prefMethod: Number;
  res: boolean = false;
  @ViewChild('code1Input', { static: false }) code1Input: ElementRef;
  @ViewChild('code2Input', { static: false }) code2Input: ElementRef;
  @ViewChild('code3Input', { static: false }) code3Input: ElementRef;
  @ViewChild('code4Input', { static: false }) code4Input: ElementRef;
  @ViewChild('code5Input', { static: false }) code5Input: ElementRef;
  @ViewChild('code6Input') code6Input: ElementRef;
  public timedMessage: TimedMessage = new TimedMessage();

  /**
   * @param authService
   * @param aRoute
   * @param router
   * @param translation
   * @param renderer
   */
  constructor(
    public authService: LoginService,
    public aRoute : ActivatedRoute,
    private router : Router,
    private translation: TranslateService,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.timedMessage = new TimedMessage();
    this.getProfile();
    return;
  }

  ngAfterViewInit() {
    this.code1Input.nativeElement.focus();
    this.renderer.listen('document', 'click', (event) => {
      if (event.target.nodeName.toLowerCase() !== 'input') {
        if (this.inputs && this.inputs[0]?.value === '') {
          this.code1Input.nativeElement.focus();
        } else {
          for (let i = 0; this.inputs.length; i++) {
            if (this.inputs) {
              switch (i + 1) {
                case 1:
                  this.code1Input.nativeElement.focus();
                  break;
                case 2:
                  this.code2Input.nativeElement.focus();
                  break;
                case 3:
                  this.code3Input.nativeElement.focus();
                  break;
                case 4:
                  this.code4Input.nativeElement.focus();
                  break;
                case 5:
                  this.code5Input.nativeElement.focus();
                  break;
                case 6:
                  this.code6Input.nativeElement.focus();
                  break;
                default:
                  this.code1Input.nativeElement.focus();
              }
              break;
            } else {
              this.code6Input.nativeElement.focus();
            }
          }
        }
      }
    });
    this.inputs = document.querySelectorAll<HTMLInputElement>('input.code-input');
    this.inputs.forEach((i, index) => {
      i.addEventListener('keyup', (e: KeyboardEvent) => {
        if (
          [
            'Digit0',
            'Digit1',
            'Digit2',
            'Digit3',
            'Digit4',
            'Digit5',
            'Digit6',
            'Digit7',
            'Digit8',
            'Digit9',
            'Numpad0',
            'Numpad1',
            'Numpad2',
            'Numpad3',
            'Numpad4',
            'Numpad5',
            'Numpad6',
            'Numpad7',
            'Numpad8',
            'Numpad9',
            'Backspace',
          ].includes(e.code)
        ) {
          if (e.code !== 'Backspace') {
            this.inputs.item(index).value = e.key;
            if (index === 5) {
              this.inputs.item(0).focus();
            } else {
              this.inputs.item(index + 1).focus();
            }
          } else {
            if (this.inputs[0].value !== '') {
              this.inputs.item(index - 1).focus()
            }
          }
        }
      });

      i.addEventListener('paste', (e: ClipboardEvent) => {
        e.preventDefault();
        const paste = e.clipboardData.getData('text');
        this.inputs.forEach((input, index) => {
          input.focus();
          input.value = paste[index];
        });
      });

      i.addEventListener('keydown', (e: KeyboardEvent) => {
        if (
          [
            'Digit0',
            'Digit1',
            'Digit2',
            'Digit3',
            'Digit4',
            'Digit5',
            'Digit6',
            'Digit7',
            'Digit8',
            'Digit9',
            'Numpad0',
            'Numpad1',
            'Numpad2',
            'Numpad3',
            'Numpad4',
            'Numpad5',
            'Numpad6',
            'Numpad7',
            'Numpad8',
            'Numpad9',
            'Backspace',
          ].includes(e.code)
        ) {
          this.inputs.item(index).value = '';
        }
      });
    });
    return;
  }

  /**
   * Check 6 digit code inputs
   * @description Should be 6 digit.
   */

  checkInputs() {
    let c = true;
    this.inputs.forEach((i) => {
      c = i.value !== '' && c;
    });
    this.res = c;
  }

  getProfile(){
    this.authService.getAuthProfile().subscribe((data) => {
      this.authProfile = data;
      this.aRoute.params.subscribe(params => {
        if (params.method !== undefined) {
          this.method = Number(params.method)
        }
        else {
          this.method = data.prefMethod
        }
        if(this.method === 1){
          this.authService.initiateAuth(Number(data.prefMethod),data.mail).subscribe({
            next:(e) =>{
              this.timedMessage.show(
                this.translation.translate('mail'),
                Alert.success,
                3000
              );
            },
            error:() => {
              this.timedMessage.show(
                this.translation.translate('unexErr'),
                Alert.danger,
                3000
              );
            }
          })
        }
      });
    })
  }

  /**
   * Send verify code
   * @description Should be 6 digit.
   */
  sendCode() {
    let code = '';
    this.inputs.forEach((i) => {
      code += i.value;
    });
    this.authService.verifyCode(code, this.method).subscribe({
      next:(data : {message : string , state : string}) => {
        if(data.state === '200'){
          this.timedMessage.show(
            data.message,
            Alert.success,
            3000
          );
          this.router.navigate(['/Billing/List']);
        }
      },
      error:() => {
        this.res = false;
        this.timedMessage.show(
          this.translation.translate('unexErr'),
          Alert.danger,
          3000
        );
      }
    })
  }

  public back2login() {
      this.authService.logout();
  }

  /**
   * Method to submit the form when the sixth character is entered
   */
  submitFormOnCode6() {
    const code6Value = this.code6Input.nativeElement.value;
    if (code6Value.length === 1) {
      this.sendCode(); // Call your submit method here
    }
  }

  /**
   * Method to check the input on paste event
   * @param event
   */
  onPaste(event: ClipboardEvent) {
    setTimeout(() => {
      const code6Value = this.code6Input.nativeElement.value;
      if (code6Value.length === 1) {
        this.sendCode(); // Call your submit method here
      }
    }, 0);
  }
}
