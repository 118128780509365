import {NgModule} from '@angular/core';
import {AUTH_SERVICE, AuthModule, PROTECTED_FALLBACK_PAGE_URI, PUBLIC_FALLBACK_PAGE_URI} from 'ngx-auth';

import {LoginService} from './login.service';

@NgModule({
  imports: [AuthModule],
  providers: [
    {provide: PROTECTED_FALLBACK_PAGE_URI, useValue: '/Login'},
    {provide: PUBLIC_FALLBACK_PAGE_URI, useValue: '/Login'},
    {provide: AUTH_SERVICE, useClass: LoginService}
  ],
  exports: [],
  declarations: []
})
export class AuthenticationModule {

}
