/**
 * An Object to manage available Languages and the currently used one.
 */
export class Language {

  /**
   * Available languages.
   */
  readonly languages: string[];

  /**
   * The currently used language.
   */
  private currentLanguage: string;

  /**
   * Constructor
   */
  constructor() {
    this.languages = [];
  }

  /**
   * Returns this.languages.
   */
  public getLanguages(): string[] {
    return this.languages;
  }

  /**
   * Returns this.currentLanguage.
   */
  public getCurrentLanguage(): string {
    return this.currentLanguage;
  }

  /**
   * Adds a language to the available languages.
   * @param language
   * language to be added.
   */
  public add(language: string) {
    this.languages.push(language);
  }

  /**
   * Sets the current language if the specified language exists. Returns true if the specified language exists false if not.
   * @param language
   * The language to be set.
   */
  public selectLanguage(language: string) {
    if (this.languageExists(language)) {
      this.currentLanguage = language;
    }
  }

  /**
   * Checks weather a language exists.
   * @param language
   * the language to be checked.
   */
  public languageExists(language: string): boolean {
    for (let i = 0; i < this.languages.length; i++) {
      if (this.languages[i] === language) {
        return true;
      }
    }
    return false;
  }

  /**
   * Initializes the current language.
   */
  public init(): number {
    let index = 0;
    switch (navigator.language) {
      case 'de-DE':
        index = this.languages.indexOf('Deutsch') + 1;
        if (index > 0) {
          this.selectLanguage('Deutsch');
          this.selectAndReorder('Deutsch');
        }
        break;
      case 'en-EN':
        index = this.languages.indexOf('English') + 1;
        if (index > 0) {
          this.selectLanguage('English');
          this.selectAndReorder('English');
        }
        break;
      default:
        index = this.languages.indexOf('Deutsch') + 1;
        this.selectLanguage('Deutsch');
        this.selectAndReorder('Deutsch');
    }
    return index;
  }

  /**
   * Sets the specified language as the selected one and reorders languages so the specified language is the first one.
   * @param language
   * the language to be set and placed as the first one in languages.
   */
  public selectAndReorder(language: string) {
    if (this.languageExists(language)) {
      this.languages.splice(this.languages.indexOf(language), 1);
      this.languages.unshift(language);
    }
  }

  /**
   * Gets the (array-)index of the current language.
   * (Necessary for the dictionary. Works because as well this available languages as the dictionary were created from the same source.)
   */
  public getIndex() {
    return this.languages.indexOf(this.currentLanguage) + 1;
  }
}
