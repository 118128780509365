import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Order} from '../order';

@Component({
  selector: 'app-order-badge',
  templateUrl: './order-badge.component.html',
  encapsulation: ViewEncapsulation.Emulated
})
export class OrderBadgeComponent {

  @Input() order: Order = new Order({});
  @Input() element = 0;

}
