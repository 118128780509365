import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {AuthService} from 'ngx-auth';
import {Observable, of} from 'rxjs';
import {ApiService} from '../api.service';
import {map} from "rxjs/operators";
import {AuthProfile} from "../objects/bill";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class LoginService implements AuthService {

  /**
   * To store an interrupted url (due to authentication issues)
   */
  private interruptedUrl: string;

  /**
   * Constructor
   * @param http
   * HttpClient to start API-Requests
   * @param router
   */
  constructor(private http: HttpClient, private router: Router) {
  }

  /**
   * Returns the AccessToken.
   */
  getAccessToken(): Observable<string> {
    return of(localStorage.getItem('accessToken'));
  }

  /**
   * Checks weather a user is authorized to enter a certain page.
   */
  isAuthorized(): Observable<boolean> {
    return of(!!localStorage.getItem('accessToken'));
  }

  /**
   * Needed by implementation. Not needed by project.
   * @param response
   * any response
   */
  refreshShouldHappen(response: HttpErrorResponse): boolean {
    return undefined;
  }

  /**
   * Required by implementation but not used.
   */
  refreshToken(): Observable<any> {
    return of(undefined);
  }

  /**
   * Invoked when the user tries to enter a page where authentication is needed but user is not authenticated.
   * @param url
   * the url the user tried to enter.
   */
  setInterruptedUrl(url: string): void {
    this.interruptedUrl = url;
  }

  /**
   * Returns Url interrupted due to authentication issues to redirect after access could be granted.
   */
  getInterruptedUrl(): string {
    return this.interruptedUrl;
  }

  /**
   * Does not do a thing. Function is needed as part of the implementation.
   * @param url
   * any url
   */
  verifyTokenRequest(url: string): boolean {
    return undefined;
  }

  /**
   * Verifies the provided login-data is correct and sets the accessToken aka bearer-token.
   * @param email
   * the account-email
   * @param password
   * the account-password
   */
  public login(email: string, password: string): Observable<any> {
    return this.http.post<any>(ApiService.getCurrentApi() + '/login', {
      'Login': email,
      'Password': password
    }).pipe(map(
      data => {
        localStorage.setItem('accessToken', data.Token);
      }
    ));
  }

  /**
   * Removes the accessToken aka bearer-token.
   */
  public logout() {
    localStorage.removeItem('accessToken');
    this.router.navigateByUrl("/login");
  }

  public getQrCode(userid: number): Observable<any> {
    return this.http
      .post<any>(ApiService.getCurrentApi() + '/2FA', {
        contactId: userid,
      })
      .pipe(map((data) => data.qrcode));
  }

  public initiateAuth(prefMethod: number, email: String) {
    return this.http
      .post<any>(ApiService.getCurrentApi() + '/2FA', {
        PrefMethod: prefMethod,
        Mail: email,
      })
      .pipe(map((data) => data));

  }

  public getAuthProfile() : Observable<AuthProfile> {
    return this.http.get<AuthProfile>(ApiService.getCurrentApi() + '/2FA');
  }

  public verifyCode(code: String, method: Number) {
    return this.http.put(ApiService.getCurrentApi() + '/2FA',{method: method, code: code});
  }

}
