<div class="d-flex justify-content-center">
  <div>
    {{ 'Seite' | translate }} {{ getPage() }} {{ 'von' | translate }} {{ maxPages }}
    ( {{ 'Gesamt' | translate }}: {{ totalCount }} )
  </div>
  <div class="btn-group dropup" *ngIf="limitsEnabled">
    &nbsp;{{ 'PostenProSeite' | translate }}&nbsp;
    <div class="btn btn-outline-secondary btn-super-slim dropdown-toggle" (click)="toggleDropdown()">
      {{ defaultLimit }}
    </div>
    <div class="dropdown-menu {{ dropdownVisible ? 'show' : '' }}">
      <div
        *ngFor="let l of limits"
        class="dropdown-item"
        (click)="setLimit(l)"
      >{{ l }}</div>
    </div>
  </div>
</div>
