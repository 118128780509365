import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {InvoiceService} from "../../dependencies/invoice.service";
import {Permissions} from "../../dependencies/objects/bill";

@Component({
  selector: 'app-oauth',
  templateUrl: './oauth.component.html',
  styleUrls: ['./oauth.component.scss']
})
export class OauthComponent implements OnInit {

  public token: any;

  public timeLeft = 5;

  public timeCheck = 5;

  public interval;

  public permissions: Permissions

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public invoice: InvoiceService
  ) {
    if (this.route.snapshot.params.token) {
      localStorage.setItem('accessToken', this.route.snapshot.params.token);
    }
  }

  ngOnInit(): void {
    this.checkPermission();
  }

  public checkPermission() {
    return this.invoice.checkPermissions(this.route.snapshot.params.token).subscribe(values => {
      this.permissions = values;
      this.startTimer();
    });
  }

  public startTimer() {
    this.interval = setInterval(() => {
      if (this.timeCheck > 0) {
        this.timeCheck--;
      } else {
        if (this.timeLeft > 0) {
          this.timeLeft--;
        } else {
          if (this.permissions.User && this.permissions.Permission && this.permissions.AccessToBillingPortal) {
            localStorage.setItem('accessToken', this.permissions.Key);
            this.router.navigateByUrl('/Billing/List');
          } else {
            localStorage.removeItem('accessToken');
            this.router.navigateByUrl('/Login');
          }
          clearInterval(this.interval);
        }
      }
    }, 1000);
  }

}
