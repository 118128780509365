import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {InvoiceService} from '../../dependencies/invoice.service';
import {LoginService} from '../../dependencies/authentication/login.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {

  /**
   * Constructor
   */
  constructor(
    public router: Router,
    public service: InvoiceService,
    public login: LoginService) {}

  /**
   * Destroys the authentication and routes to the Login-page.
   */
  public logout() {
    this.login.logout();
    this.router.navigate(['/Login']);
  }

  /**
   * Resets the searchTxt, gathers Billings again and reloads the page.
   */
  public reset() {
    this.reloadRoute(['/Billing/List']);
  }

  /**
   * Navigates to the provided route and ensures to reload the page.
   * @param route
   * The route to be loaded.
   */
  public reloadRoute(route: string[]) {
    // That complicate to force reload. Safes data-rate compared to other possibilities.
    this.router.navigate([''], {skipLocationChange: true}).then(
      () => {
        this.router.navigate(route);
      }
    );
  }
}
