<div class="advanced_search">
    <div class="user-input-wrp d-flex">
      <div class="input-group">
        <input class="form-control"
               placeholder="{{'placeholder' | translate}}"
               type="text"
               [(ngModel)]="searchText"
               spellcheck="false"
               (keydown)="onInput($event)"
               (input)="analyzeQuery()">
        <div class="input-group-append" (click)="submit()" type="submit" title="{{'search' | translate}}">
          <span class="input-group-text">
            <i class="fa fa-lg fa-search"></i>
          </span>
        </div>
        <div class="input-group-append" (click)="clearAll()" type="submit" title="{{'reset' | translate}}">
          <span class="input-group-text">
            <i class="fa fa-lg fa-ban"></i>
          </span>
        </div>
      </div>
  </div>
  <ul class="ul-dropdown" *ngIf="searchService.getMatches().length > 0 && active === 1">
    <li class="li-dropdown" (click)="clickSelect(matches)" *ngFor="let matches of searchService.getMatches()">
      {{ matches.attributeName }}
    </li>
  </ul>
  <ul class="underline" *ngIf="searchAnalyze.length > 0 && enableUnderline">
    <li *ngFor="let decorator of searchAnalyze" [ngClass]="decorator.objectType + ' ' + decorator.objectOperant"
        style="display: block;"
    >{{ decorator.objectString }}</li>
  </ul>
</div>

