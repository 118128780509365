import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdvancedSearchComponent} from './advanced-search.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {AdvancedSearchService} from './advanced-search.service';
import {TranslateService} from '../../translation/translate.service';
import {TranslatePipeMock} from '../../translation/translate.pipe.mock';
import {TranslatePipe} from '../../translation/translate.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  declarations: [
    AdvancedSearchComponent,
    TranslatePipeMock,
    TranslatePipe,
  ],
  providers: [AdvancedSearchService,
    TranslateService],
  exports: [
    AdvancedSearchComponent,
    TranslatePipe
  ],
})
export class AdvancedSearchModule {
}
