import {OrderElement} from './order-element';

/**
 * Describes ordering of elements.
 */
export class Order {
  private orderElements: object;

  /**
   * The order of Elements which shell be ordered.
   */
  private order: OrderElement[];

  /**
   * Constructor. Initializes the order-attribute
   */
  constructor(order: object) {
    this.orderElements = order;
    this.reset();
  }

  /**
   * Returns the elements to be ordered by and an boolean describing whether it should be ordered ascending or descending.
   */
  public getOrder(): OrderElement[] {
    return this.order;
  }

  public getOrderRight(): OrderElement[] {
    return this.order.slice().reverse();
  }

  /**
   * Add an OrderElement to the end of the order array.
   * If an similar OrderElement already exists this Object is deleted and pushed again to keep the order.
   * @param orderElement
   * The criteria (see equivalent enum) to be added to the order.
   * @param ascending
   * true if the order shell be ascending (for descending it shell be false).
   */
  private add(orderElement: number, ascending: boolean) {
    this.remove(orderElement);
    this.order = [new OrderElement(orderElement, ascending)];
  }

  /**
   * Toggles an OrderElements ascending-attribute if the specified criteria exists in this order-list.
   * @param orderElement
   * the criteria to be specified.
   */
  private toggle(orderElement: number) {
    for (let i = 0; i < this.order.length; i++) {
      if (this.order[i].isTypeOf(orderElement)) {
        this.order[i].toggle();
        this.add(this.order[i].getOrderElement(), this.order[i].getAscending());
      }
    }
  }

  /**
   * Resets the order
   */
  public reset() {
    this.order = [];
  }

  /**
   * Returns a number indicating the importance of the orderElement in the order. (1 = most important - 3 least important)
   * @param orderElement
   * the orderElement which importance must be known.
   */
  public weight(orderElement: number): number {
    for (let i = this.order.length - 1; i >= 0; i--) {
      if (this.order[i].getOrderElement() === orderElement) {
        return this.order.length - i;
      }
    }
    return 0;
  }

  /**
   * Removes an orderElement from the order.
   * @param orderElement
   * the orderElement which should be removed.
   */
  private remove(orderElement: number) {
    for (let i = 0; i < this.order.length; i++) {
      if (this.order[i].getOrderElement() === orderElement) {
        for (let e = i; e < this.order.length - 1; e++) {
          this.order[e] = this.order[e + 1];
        }
        this.order.splice(this.order.length - 1, 1);
      }
    }
  }

  /**
   * checks weather a orderElement exists in order or not.
   * @param orderElement
   * the orderElement to be checked.
   */
  public exists(orderElement: number): boolean {
    for (let i = 0; i < this.order.length; i++) {
      if (this.order[i] !== undefined && this.order[i].getOrderElement() === orderElement) {
        return true;
      }
    }
    return false;
  }

  /**
   * Changes the order to set the specified orderElement's weight to one or if already
   * so toggle the order direction of the specified orderElement.
   * @param orderElement
   * the orderElement to dominate the order.
   */
  public orderBy(orderElement: number) {
    if (this.exists(orderElement)) {
      this.toggle(orderElement);
    } else {
      this.add(orderElement, true);
    }
  }

  /**
   * Checks weather a orderElement exists in order an if so it checks weather the orderElement shell be ordered ascending.
   * @param orderElement
   * the orderElement to be checked.
   */
  public ascending(orderElement: number): boolean {
    for (let i = 0; i < this.order.length; i++) {
      if (this.order[i] !== undefined && this.order[i].getOrderElement() === orderElement) {
        return this.order[i].getAscending();
      }
    }
    return false;
  }

  /**
   * Checks weather a orderElement exists in order an if so it checks weather the orderElement shell be ordered descending.
   * @param orderElement
   * the orderElement to be checked.
   */
  public descending(orderElement: number): boolean {
    for (let i = 0; i < this.order.length; i++) {
      if (this.order[i] !== undefined && this.order[i].getOrderElement() === orderElement) {
        return !this.order[i].getAscending();
      }
    }
    return false;
  }
}
