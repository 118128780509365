import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {FormControl, FormGroupDirective, NgForm, Validators} from "@angular/forms";
import {ErrorStateMatcher} from "@angular/material/core";
import {AuthProfile} from "../../dependencies/objects/bill";
import {LoginService} from "../../dependencies/authentication/login.service";
import {TranslateService} from "../../dependencies/translation/translate.service";

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-authmethod',
  templateUrl: './authmethod.component.html',
  styleUrls: ['./authmethod.component.scss']
})
export class AuthmethodComponent implements OnInit {
  emailFormControl = new FormControl("", [
    Validators.required,
    Validators.email,
  ]);

  googleFormControl = new FormControl("", [
    Validators.required,
    Validators.email,
  ]);

  matcher = new MyErrorStateMatcher();

  authProfile: AuthProfile;

  /**
   * User ID
   */
  userID: number;

  /**
   * QR code for Google Auth
   */
  qr: String;

  /**
   * Email for Mail Auth
   */
  email: string;

  /**
   * Validation of email
   */
  emailValid: boolean = true;

  timeLeft: number = 10;
  interval;
  public type: string = 'appAuth';

  /**
   * @param authService
   * @param translation
   * @param router
   */
  constructor(
    public authService: LoginService,
    private translation: TranslateService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.getAuthProfile();
    if (this.type === 'appAuth') {
      this.initiateQrAuth(false);
    }
    return;
  }

  public checkType(val: string) {
    this.type = val;
    if (this.type === 'appAuth') {
      this.initiateQrAuth(false);
    }
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  /**
   * Get qr code
   * @param {Number} userid
   * @description Should get base64.
   */
  getAuthProfile() {
    return this.authService.getAuthProfile().subscribe((data) => {
      this.authProfile = data;
      this.qr = data.qrCode;
      this.email = data.mail.toString()
    });
  }

  initiateQrAuth(submit) {
    if (this.qr && submit) {
      this.router.navigateByUrl("/Authenticator/2");
    } else {
      this.authService.initiateAuth(2, this.email).subscribe((data) => {
        this.qr = data.message;
        this.startTimer();
      });
    }
  }

  /**
   * Set mail for 2 factor authentication
   */
  initiateMailAuth() {
    if (this.validateEmail(this.email)) {
      this.authService.initiateAuth(1, this.email).subscribe((data) => {
        this.router.navigateByUrl("/Authenticator/1");
      });
    }
  }

  /**
   * Validate Email
   * @param {String} email
   * @return {boolean}
   */
  validateEmail(email: String): boolean {
    let reg = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    if (reg === null) {
      this.emailValid = false;
      return false;
    } else {
      this.emailValid = true;
      return true;
    }
  }

  public back2login() {
    this.authService.logout();
  }
}
