<div class="navbar navbar-dark bg-dark fixed-top">
  <div class="d-none d-sm-block index"><a class="navbar-brand" [routerLink]="['/Billing/List']">
    {{'title' | translate}}</a>
  </div>

  <div class="ml-md-auto mr-md-auto w-50-sm">
    <div class="float-left cursor-pointer w-100" title="{{'search' | translate}}">

    </div>
  </div>

  <div class="float-right index">
    <div class="float-left cursor-pointer mr-3 mt-1" title="{{'overviewBillings' | translate}}"
         [routerLink]="['/Billing/List']">
      <i class="fa fa-lg fa-th-list"></i>
    </div>
    <div class="float-left cursor-pointer mr-3 mt-1" title="{{'profile' | translate}}" [routerLink]="['Profile']">
      <i class="fa fa-lg fa-user"></i>
    </div>
    <div class="float-left cursor-pointer mt-1" title="{{'logout' | translate}}" (click)="logout()">
      <i class="fa fa-lg fa-power-off"></i>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
