import {Component, OnInit} from '@angular/core';
import {InvoiceService} from '../../dependencies/invoice.service';
import {TimedMessage} from '../../dependencies/gui-elements/timed-message/timed-message';
import {Billing, BillingDetails} from '../../dependencies/objects/bill';
import {Alert} from '../../dependencies/enum/alert.enum';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '../../dependencies/translation/translate.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
  /**
   * to show error message
   */
  public timedMessage: TimedMessage = new TimedMessage();

  /**
   * billing details
   */
  public BillDetails: BillingDetails[];

  /**
   * billing attachments
   */
  public BillAttachments = [];

  /**
   * saving blob file
   */
  public file: Blob;

  /**
   * Constructor
   * @param service InvoiceService
   * @param route ActivatedRoute
   * @param translate TranslateService
   */
  constructor(
    private service: InvoiceService,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {}

  /**
   * on init function
   */
  ngOnInit() {
    if (this.route.snapshot.params) {
      this.getBill(this.route.snapshot.params.ID);
    }
  }

  /**
   * get billing details
   * @param billingID as number
   */
  public getBill(billingID: number) {
    this.service.getBill(billingID).subscribe(details => {
        this.BillDetails = details;
        this.BillDetails.filter(v => {
          if (!this.BillAttachments.includes(v.AnhangID)) {
            this.BillAttachments.push(v.AnhangID);
          }
        })
    }, () => {
        this.timedMessage.show(
          this.translate.translate('Rechnungsdetails können nicht angezeigt werden!'),
          Alert.danger
        );
    }, () => {
      // Loading spinner
    });
  }

  public showFiles(id: number) {
    this.service.showFiles(id).subscribe(
      (data) => {
        data.openInWindow();
        console.log(id)
      },
      () => {
        this.timedMessage.show(
          this.translate.translate('Die Rechnung konnte nicht heruntergeladen werden!'),
          Alert.danger
        );
      }, () => {
        this.getBill(this.route.snapshot.params.ID);
      }
    );
  }
}
