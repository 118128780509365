<div class="container margin-top">
  <div class="card">
    <div class="card-header">
      <h3>Einzelanmeldung</h3>
    </div>
    <div class="card-body">
      <span>Authorisierung wird überprüft...</span>
      <br><br>
      <span><span *ngIf="this.timeCheck > 0" class="loader"></span><i *ngIf="this.timeCheck < 1" class="{{ permissions.User ? 'fas fa-check success' : 'fas fa-times failed'}}"></i> Benutzer vorhanden</span>
      <br><br>
      <span><span *ngIf="this.timeCheck > 0" class="loader"></span><i *ngIf="this.timeCheck < 1" class="{{ permissions.Permission ? 'fas fa-check success' : 'fas fa-times failed'}}"></i> Berechtigungscheck</span>
      <br><br>
      <span><span *ngIf="this.timeCheck > 0" class="loader"></span><i *ngIf="this.timeCheck < 1" class="{{ permissions.AccessToBillingPortal ? 'fas fa-check success' : 'fas fa-times failed'}}"></i> Kunde ist verfügbar</span>
      <br><br>
      <span *ngIf="permissions.User && permissions.Permission && permissions.AccessToBillingPortal && this.timeCheck < 1">Weiterleitung erfolgt in: <b>{{ this.timeLeft }}</b></span>
      <span *ngIf="!permissions.User || !permissions.Permission || !permissions.AccessToBillingPortal && this.timeCheck < 1">Einzelanmeldung ist nicht möglich. Weiterleitung in: <b>{{ this.timeLeft }}</b></span>
    </div>
  </div>
</div>
