import {Injectable} from '@angular/core';
import {Language} from './language';
import {TranslateMockData} from './translate.mock.data';

@Injectable({
  providedIn: 'root'
})
export class TranslateServiceMock {

  /**
   * Keeps every translation.
   */
  private dictionary: string[][];

  /**
   * To manage languages.
   */
  private language: Language = new Language();

  /**
   * Constructor
   */
  constructor() {
    this.dictionary = [[]];
    this.readDictionary();
  }

  /**
   * Returns the currently used language.
   */
  public getCurrentLanguage(): string {
    return this.language.getCurrentLanguage();
  }

  /**
   * Sets the currently used language. Is only set if language is known.
   * @param language
   * The language to be set.
   */
  public setCurrentLanguage(language: string): void {
    this.language.selectLanguage(language);
  }

  /**
   * Finds the translation in the dictionary to the given value.
   * @param key
   * The value to be translated.
   */
  public translate(key: string): string {
    if (key === 'language') {
      return 'English';
    }
    if (this.dictionary !== [[]]) {
      for (let i = 0; i < this.dictionary[0].length; i++) {
        if (this.dictionary[0][i] === key) {
          if (this.dictionary[this.language.getIndex()] !== undefined) {
            return this.dictionary[this.language.getIndex()][i];
          }
        }
      }
    }
    return key;
  }

  /**
   * returns an array of available languages.
   */
  public getAvailableLanguages(): string[] {
    return this.language.getLanguages();
  }

  /**
   * Requests the dictionary.csv and converts it to this.dictionary.
   */
  private readDictionary(): void {
    this.dictionary = TranslateMockData.dictionary;
  }

}
