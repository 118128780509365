/**
 * Bootstrap-alert-classes.
 */
export enum Alert {
  info = 'alert-info',
  secondary = 'alert-secondary',
  danger = 'alert-danger',
  warning = 'alert-warning',
  success = 'alert-success',
  light = 'alert-light',
  dark = 'alert-dark'
}
