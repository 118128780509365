import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {PasswordFormComponent} from '../../dependencies/gui-elements/password-form/password-form.component';
import {InvoiceService} from '../../dependencies/invoice.service';
import {TranslateService} from '../../dependencies/translation/translate.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends PasswordFormComponent implements OnInit {
  /**
   * Constructor
   * @param service service
   * @param translate translate
   * @param route route
   * @param router router
   * @param location location
   */
  constructor(service: InvoiceService,
              translate: TranslateService,
              route: ActivatedRoute,
              router: Router,
              private location: Location) {
    super(service, translate, route, router);
  }

  ngOnInit() {
    super.ngOnInit();
    this.service.getToken(this.hash).subscribe(() => {
      this.denied = false;
    }, () => {
      this.denied = true;
    });
  }

  /**
   * Redirects to the previous page.
   */
  public back() {
    this.router.navigateByUrl('/Login');
  }

}
