<div *ngIf="checkAuthorized() | async">
  <app-advanced-search
    class="input-group"
    (searchSubmit)="searchSubmit($event)"
    [searchAttributes]="searchAttributes">
  </app-advanced-search>
  <div class="container margin-top">
    <app-timed-message [alert]="timedMessage"></app-timed-message>
    <div class="d-flex justify-content-between mb-2">
      <span class="header">Rechnungen im Überblick</span>
      <div class="btn-group" >
        <label class="btn border {{ type === 'Kairos' ? 'btn-secondary active' : '' }}" (click)="checkType('Kairos')">
          <i class="fas fa-file-invoice-dollar"></i><span> {{ 'Rechnungen' | translate }}</span>
          <span class="badge badge-danger badge-pill ml-2" *ngIf="bills[0] && bills[0]?.Rechnungen > 0">{{ bills[0]?.Rechnungen }}</span>
        </label>
        <label class="btn border {{ type === 'Extern' ? 'btn-secondary active' : '' }}" (click)="checkType('Extern')">
          <i class="fas fa-file-alt"></i><span> {{ 'Gutschriften' | translate }}</span>
          <span class="badge badge-danger badge-pill ml-2" *ngIf="bills[0] && bills[0]?.Gutschriften > 0">{{ bills[0]?.Gutschriften }}</span>
        </label>
      </div>
      <div class="btn-group">
        <label class="btn border {{ read === 'alle' ? 'btn-secondary active' : '' }}" (click)="checkRead('alle')">
          <i class="fa-solid fa-bars"></i><span> {{ 'Alle' | translate }}</span>
        </label>
        <label class="btn border {{ read === 'false' ? 'btn-secondary active' : '' }}" (click)="checkRead('false')">
          <i class="fa-regular fa-envelope"></i><span> {{ 'Ungelesen' | translate }}</span>
        </label>
        <label class="btn border {{ read === 'true' ? 'btn-secondary active' : '' }}" (click)="checkRead('true')">
          <i class="fa-regular fa-envelope-open"></i><span> {{ 'Gelesen' | translate }}</span>
        </label>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-scroll table-bordered table-sm-sm">
        <thead>
        <tr>
          <th class="text-left cursor-pointer" (click)="orderBy(1)">
            {{'billingNumber' | translate}}
            <span class="badge badge-pill badge-secondary" *ngIf="order.exists(1)">
            <i class="{{ order.ascending(1) ? 'fas fa-long-arrow-alt-down' : '' }}
                      {{ order.descending(1) ? 'fas fa-long-arrow-alt-up': '' }}"
            ></i>
          </span>
          </th>
          <th class="text-center cursor-pointer" (click)="orderBy(2)">
            {{'travelAgency' | translate}}
            <span class="badge badge-pill badge-secondary" *ngIf="order.exists(2)">
            <i class="{{ order.ascending(2) ? 'fas fa-long-arrow-alt-down' : '' }}
                      {{ order.descending(2) ? 'fas fa-long-arrow-alt-up': '' }}"
            ></i>
          </span>
          </th>
          <th class="text-center cursor-pointer" (click)="orderBy(3)">
            {{ 'dateInvoice' | translate}}
            <span class="badge badge-pill badge-secondary" *ngIf="order.exists(3)">
            <i class="{{ order.ascending(3) ? 'fas fa-long-arrow-alt-down' : '' }}
                      {{ order.descending(3) ? 'fas fa-long-arrow-alt-up': '' }}"
            ></i>
          </span>
          </th>
          <th class="text-center cursor-pointer" (click)="orderBy(4)">
            {{ 'Mandant' | translate}}
            <span class="badge badge-pill badge-secondary" *ngIf="order.exists(4)">
            <i class="{{ order.ascending(4) ? 'fas fa-long-arrow-alt-down' : '' }}
                      {{ order.descending(4) ? 'fas fa-long-arrow-alt-up': '' }}"
            ></i>
          </span>
          </th>
          <th class="text-center cursor-pointer" (click)="orderBy(5)">
            <div *ngIf="type === 'Kairos'">
              <span>{{ 'totalAmount' | translate}}</span> <span class="netto"> (Netto)</span>
            </div>
            <div *ngIf="type === 'Extern'">
              <span>{{ 'Download' | translate}}</span>
            </div>
            <span class="badge badge-pill badge-secondary" *ngIf="order.exists(5)">
            <i class="{{ order.ascending(5) ? 'fas fa-long-arrow-alt-down' : '' }}
                      {{ order.descending(5) ? 'fas fa-long-arrow-alt-up': '' }}"
            ></i>
          </span>
          </th>
          <th *ngIf="type === 'Kairos'" class="text-right cursor-pointer" (click)="orderBy(6)">
            <div>
              <span>{{ 'totalAmount' | translate}}</span> <span class="netto"> (Brutto)</span>
            </div>
            <span class="badge badge-pill badge-secondary" *ngIf="order.exists(6)">
            <i class="{{ order.ascending(6) ? 'fas fa-long-arrow-alt-down' : '' }}
                      {{ order.descending(6) ? 'fas fa-long-arrow-alt-up': '' }}"
            ></i>
          </span>
          </th>
        </tr>
        </thead>
        <tbody style="position: relative;">
        <ng-container *ngFor="let bill of bills">
          <div class="read">
            <span class="text-center icon"><span class="marker">{{ bill.UserRead ? '' : '* ' }}</span><i class="{{ bill.UserRead ? '' : 'fa-regular fa-envelope'}}"></i></span>
          </div>
          <div class="div-element" *ngIf="bill.Loading">
            <span class="loader"></span>
          </div>
          <tr class="cursor-pointer hover {{ bill.UserRead ? '' : 'fontWeight' }}" (click)="bill.Rechnungstyp === 'Kairos' ? this.routeToDetails(bill.ID) : getFile(bill.DocumentID)">
            <td class="text-left">{{ bill.Rechnungsnummer }}</td>
            <td class="text-center">{{ bill.TravelAgency }}</td>
            <td class="text-center">{{ bill.Rechnungsdatum }}</td>
            <td class="text-center">{{ bill.Mandant }}</td>
            <td class="text-center">{{ bill.Rechnungstyp === 'Kairos' ? bill.GesamtSumme + ' €' : '' }}
              <i *ngIf="bill.Rechnungstyp === 'Extern'" class="fas fa-download"></i>
            </td>
            <td *ngIf="bill.Rechnungstyp === 'Kairos'" class="{{ bill.Rechnungstyp === 'Kairos' ? 'text-right' : 'text-center' }}">
              {{ bill.Rechnungstyp === 'Kairos' ? bill.GesamtSummeBrutto + ' €' : '' }}
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>
    <app-paging-footer *ngIf="bills!==undefined"
                       [defaultLimit]="limit"
                       [limits]="itemLimits"
                       [page]="uPage"
                       [totalItemCount]="totalCount"
                       (pageChange)="pageChange($event)"
                       (currentLimit)="setLimit($event)"
    ></app-paging-footer>
  </div>
</div>
