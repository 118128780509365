import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Language} from './language';
import {throwError} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  /**
   * Keeps every translation.
   */
  private dictionary: string[][];

  /**
   * To manage languages.
   */
  private language: Language = new Language();

  /**
   * Constructor
   * @param http
   * HttpClient to handle the csv-import.
   */
  constructor(private http: HttpClient) {
    this.dictionary = [[]];
    this.readDictionary();
  }

  /**
   * Returns the currently set language.
   */
  public getCurrentLanguage(): string {
    return this.language.getCurrentLanguage();
  }

  /**
   * Sets the currently used language. Is only set if language is known.
   * @param language
   * The language to be set.
   */
  public setCurrentLanguage(language: string): void {
    this.language.selectLanguage(language);
  }

  /**
   * Finds the translation in the dictionary to the given value.
   * @param key
   * The value to be translated.
   */
  public translate(key: string): string {
    for (let i = 0; i < this.dictionary[0].length; i++) {
      if (this.dictionary[0][i] === key && this.dictionary[this.language.getIndex()] !== undefined) {
        return this.dictionary[this.language.getIndex()][i];
      }
    }
    return key;
  }

  /**
   * returns an array of available languages.
   */
  public getAvailableLanguages(): string[] {
    return this.language.getLanguages();
  }

  /**
   * Requests the dictionary.csv and converts it to this.dictionary.
   */
  public readDictionary(): void {
    let index = 0;
    this.http.get('./assets/dictionary.csv', {responseType: 'text'}).subscribe(
      data => {
        const translationStrings: string[] = data.split('\n');
        const translations: string[][] = [];
        for (let i = 0; i < translationStrings.length; i++) {
          if (translationStrings[i] !== '') {
            translations.push(
              translationStrings[i].split(';')
            );
            for (let e = 0; e < translations[i].length; e++) {
              if (this.dictionary[e] === undefined) {
                this.dictionary[e] = [];
              }
              if (translations[i][e] === 'lang') {
                const languages = translations[i].slice(1, translations[i].length);
                for (let a = 0; a < languages.length; a++) {
                  if (languages[a] !== '') {
                    this.language.add(languages[a]);
                  }
                }
                index = this.language.init();
              }
              this.dictionary[e].push(translations[i][e]);
            }
          }
        }
      },
      () => {
        return throwError('Could not read dictionary');
      },
      () => {
        const placeholder = this.dictionary[index];
        for (let e = index; e > 1; e--) {
          this.dictionary[e] = this.dictionary[e - 1];
        }
        this.dictionary[1] = placeholder;
      }
    );
  }


  /**
   * get translation by key
   * @param key string
   */
  public instant(key: string) {
    // public perform translation
    return this.translate(key);
  }
}
