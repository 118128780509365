import {AfterViewChecked, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Limit} from './limit';

/**
 * component settings of class
 */
@Component({
  selector: 'app-paging-footer',
  templateUrl: './paging-footer.component.html',
  styleUrls: ['./paging-footer.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class PagingFooterComponent implements OnInit, AfterViewChecked {

  /**
   * The maximum number of items to be displayed at once.
   */
  @Input() defaultLimit: number;
  /**
   * The total number of items.
   */
  @Input() totalItemCount: number;
  /**
   * An array containing the supported limits (of items per page) to select from.
   */
  @Input() limits: number[] = [];
  /**
   * Triggered when the page is changed.
   * Emits the number of new page.
   */
  @Output() pageChange = new EventEmitter<number>();
  /**
   * Triggered when the page or the limit of items per page is changed.
   * Emits the currently set number of items per page to display.
   */
  @Output() currentLimit = new EventEmitter<Limit>();

  /**
   * The number of the current page. Does not have to be set as it is initialized with 1 at ngOnInit.
   */
  @Input() page: number;

  /**
   * The last page to be filled with items according to the settings/inputs.
   */
  public pageMax: number;

  /**
   * The currently set limit
   */
  public limit: number;

  /**
   * Initializes the limit-, the page- and pageMax-Attributes.
   */
  ngOnInit() {
    this.page = this.page === undefined ? 1 : this.page;
    if (this.defaultLimit === undefined) {
      if (!Array.isArray(this.limits) || this.limits.length < 1) {
        throw Error('could not set limit for items per page!');
      } else {
        this.limit = this.limits[0];
      }
    } else {
      this.limit = this.defaultLimit;
    }
    this.setPageMax();
  }

  ngAfterViewChecked() {
    // setTimeout needed to prevent error message in the js-console.
    setTimeout(
      () => {
        this.setPageMax()
      }
    );
  }

  /**
   * Triggered when the user changes to the previous page.
   * Emits the number of the new page.
   */
  public isFirstPage(): boolean {
    return this.page === 1;
  }

  public isLastPage(): boolean {
    return this.page >= this.pageMax;
  }

  public goBackwards() {
    this.page--;
    this.pageChange.emit(this.page);
  }

  public goForwards() {
    this.page++;
    this.pageChange.emit(this.page);
  }

  public getPage() {
    return (this.page === 0) ? 1 : this.page;
  }

  /**
   * Triggerd when the limit of items per page is changed.
   * Emits the new limit and the new page.
   * @param limit
   * the new limit to be set.
   */
  public selectLimit(limit: number) {
    if (this.limit < limit) {
      this.page = Math.ceil(this.page * this.limit / limit);
    } else if (this.limit > limit) {
      this.page = Math.ceil((this.page - 1) * this.limit / limit) + 1;
    } else {
      return;
    }
    this.limit = limit;
    this.setPageMax();
    if (this.page > this.pageMax) {
      this.page = this.pageMax;
    }
    if (this.page < 1) {
      this.page = 1;
    }
    const result = {
      'limit': this.limit,
      'page': this.page
    };
    this.currentLimit.emit(result);
  }

  /**
   * Sets the pageMax to the correct value.
   */
  private setPageMax() {
    if (this.totalItemCount !== undefined && this.limit !== undefined) {
      this.pageMax = Math.ceil(this.totalItemCount / this.limit);
      if (this.pageMax < 1) {
        this.pageMax = 1;
      }
    }
  }

}
