import {SearchAttribute} from './search-attribute';

export class AdvancedSearchMockData {
  public static dummySearchAttributes = [
    new SearchAttribute('Vorname', 'keyword', ''),
    new SearchAttribute('Paul', 'field', 'Vorname'),
    new SearchAttribute('Thomas', 'field', 'Vorname'),
    new SearchAttribute('Thorsten', 'field', 'Vorname'),
    new SearchAttribute('Tobias', 'field', 'Vorname'),
    new SearchAttribute('Name', 'keyword', ''),
    new SearchAttribute('Seeanner', 'field', 'Name'),
    new SearchAttribute('Hochenleitner', 'field', 'Name'),
    new SearchAttribute('Kern', 'field', 'Name'),
    new SearchAttribute('Email', 'keyword', ''),
    new SearchAttribute('t.kern@rtk-deutschland.de', 'field', 'Email'),
    new SearchAttribute('support@rt-reisen.de', 'field', 'Email'),
    new SearchAttribute('m.seeanner@rtk-deutschland.de', 'field', 'Email')
  ];
}
