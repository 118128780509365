/**
 * search object
 */
export class SearchObject {
  /**
   * can be on of ( keyword, field, string )
   */
  public objectType: string;
  /**
   * name in search string
   */
  public objectString: string;
  /**
   * defines operator
   * and for keywords, in or out for fields and strings
   */
  public objectOperant: string;
  /**
   * plain string to use for search action itself
   */
  public objectPlainString: string;
  /**
   * start position of value in string
   */
  public objectPosition: number;
  /**
   * end position of value in string
   */
  public objectEndPosition: number;
  /**
   * width of object
   */
  public objectWidth: number;

  /**
   * constructor for setting defaults
   * @param objectType string
   * @param objectString string
   * @param objectPosition string
   * @param objectEndPosition string
   */
  constructor(objectType: string = null, objectString: string = null, objectPosition: number = null, objectEndPosition: number = null) {
    this.objectPosition = objectPosition;
    this.objectString = objectString;
    this.objectType = objectType;
    switch (objectType) {
      case 'keyword': {
        this.objectOperant = 'AND';
        break;
      }
      case 'field': {
        switch (objectString.charAt(0)) {
          case '+': {
            this.objectOperant = 'IN';
            break;
          }
          case '-': {
            this.objectOperant = 'OUT';
            break;
          }
          default: {
            this.objectOperant = 'IN';
          }
            break;
        }
      }
        break;
      default: {
        if (objectString !== undefined && objectString !== null) {
          switch (objectString.charAt(0)) {
            case '+': {
              this.objectOperant = 'IN';
              break;
            }
            case '-': {
              this.objectOperant = 'OUT';
              break;
            }
            default: {
              this.objectOperant = 'IN';
            }
              break;
          }
        }
      }
    }
    this.objectPlainString = (objectString) ? objectString.replace('+#', '')
      .replace('-#', '')
      .replace(':', '')
      .trim() : null;
    if (objectString) {
      if (this.objectPlainString.charAt(0) === '-' || this.objectPlainString.charAt(0) === '+') {
        this.objectPlainString = this.objectPlainString.substr(1);
      }
    }
    this.objectEndPosition = objectEndPosition;
    this.objectWidth = this.objectEndPosition - this.objectPosition + 1;
  }
}
