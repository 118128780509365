import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '../../translation/translate.service';

/**
 * component to display paging info
 */
@Component({
  selector: 'app-paging-info',
  templateUrl: './paging-info.component.html',
  styleUrls: ['./paging-info.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class PagingInfoComponent implements OnInit {

  public dropdownVisible: boolean;
  public limitsEnabled: boolean;
  /**
   * current page
   */
  @Input() public page: number;
  /**
   * max pages
   */
  @Input() public maxPages: number;
  /**
   * total count
   */
  @Input() public totalCount: number;
  /**
   * default set limit.
   */
  @Input() defaultLimit: number;
  /**
   * limits if enabled.
   */
  @Input() public limits: number[] = [];
  /**
   * Emits the limit to be set of items per page.
   */
  @Output() limitSelected = new EventEmitter<number>();

  /**
   * constructor
   * @param translate TranslateService
   */
  constructor(public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.dropdownVisible = false;
    this.limitsEnabled = Array.isArray(this.limits) && this.limits.length > 1;
  }

  /**
   * get current page and prevent 0 page
   */
  public getPage() {
    return (this.page === 0) ? 1 : this.page;
  }

  public toggleDropdown() {
    this.dropdownVisible = !this.dropdownVisible;
  }

  public setLimit(limit: number) {
    this.defaultLimit = limit;
    this.limitSelected.emit(this.defaultLimit);
    this.toggleDropdown();
  }

}
