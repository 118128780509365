<div class="mb-2">
  <app-timed-message [alert]="timedMessage"></app-timed-message>

  <input
    class="form-control mb-2"
    type="password"
    [(ngModel)]="newPassword1"
    placeholder="{{'newPassword' | translate}}"
    (keyup)="extendedSave($event)"
  >

  <input
    class="form-control mb-2"
    type="password"
    [(ngModel)]="newPassword2"
    placeholder="{{'repeatNewPassword' | translate}}"
    (keyup)="save($event)"
  >

  <div class="progress mb-2">
    <div
      class="progress-bar"
      [ngStyle]="{'width': complexity + '%'}"
      [ngClass]="{'bg-danger': complexity <= 50, 'bg-warning': complexity > 50 && complexity < 100 || newPassword1 !== newPassword2 && complexity >= 100, 'bg-success': newPassword1 === newPassword2 && complexity >= 100}"
    >
      <span class="d-none d-lg-block" *ngIf="complexity <= 50">{{'passwordNotSafe' | translate}}</span>
      <span class="d-none d-lg-block"
            *ngIf="complexity > 50 && complexity < 100">{{'passwordNotSafeEnough' | translate}}</span>
      <span class="d-none d-lg-block" *ngIf="newPassword1 !== newPassword2 && complexity >= 100">{{'passwordNotMatching' | translate}}</span>
      <span class="d-none d-lg-block" *ngIf="newPassword1 === newPassword2 && complexity >= 100">{{'passwordIsSafe' | translate}}</span>
    </div>
  </div>

  <div class="card mb-2">
    <div class="card-header cursor-pointer" (click)="toggleInfo()">
      {{'whatIsASafePassword' | translate}}
    </div>
    <div class="card-body collapse" [ngClass]="{'show': infoToggle}">
      {{'aSafePasswordConsistsOf' | translate}}
    </div>
  </div>

  <div class="d-flex justify-content-end">
    <button class="btn btn-success" (click)="save()" [disabled]="!maySafe()">
      <i class="fa fa-save"></i> {{'savePassword' | translate}}
    </button>
  </div>
</div>
