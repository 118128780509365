<div class="container margin-top pb-3" *ngIf="BillDetails !== undefined">
  <app-timed-message [alert]="timedMessage"></app-timed-message>
  <div class="d-flex flex-sm-wrap">
    <div class="p-2 flex-sm-fill">
      <span style="font-weight: bold;font-size: x-large">Rechnungsdetails</span><br>
      <a [routerLink]="['/Billing/List']" style="font-size: x-small;">
        <i class="fa fa-angle-double-left"></i> Zur Rechnungsübersicht
      </a></div>
  </div>

  <div class="border rounded mb-3">
    <div class="d-flex flex-sm-wrap">
      <div class="p-2 flex-sm-fill">{{'billingNumber' | translate}} <br> <b>{{ BillDetails[0].Rechnungsnummer }}</b></div>
      <div class="p-2 flex-sm-fill">{{'date' | translate}} <br> <b>{{ BillDetails[0].Rechnungsdatum }}</b></div>
      <div class="p-2 flex-sm-fill text-right">
        <div class="d-flex flex-column align-items-end">
          <span class="">Download</span>
          <div class="con border rounded">
            <div class="d-flex justify-content-end">
              <button class="btn btn-outline ho cursor-pointer" (click)="showFiles(BillDetails[0].DocumentID)">
                <span class="marker">{{ BillDetails[0].UserInformed ? '' : '* ' }}</span>
                <i class="fa-regular fa-file-pdf"></i> Rechnung
                <i class="fa fa-download"></i>
              </button>
              <td class="text-center icon"></td>
            </div>
            <ng-container *ngFor="let bill of BillAttachments; let i = index">
              <div *ngIf="bill !== 0" class="d-flex justify-content-end">
                <button class="btn btn-outline ho cursor-pointer" (click)="showFiles(bill)">
                  <span class="marker">{{ BillDetails[0].UserInformed ? '' : '* ' }}</span>
                  <i class="fa-regular fa-file-excel"></i> Anhang_{{i+1}}
                  <i class="fa fa-download"></i>
                </button>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table table-striped table-sm-sm">
        <thead>
        <tr>
          <th class="text-center">Menge</th>
          <th class="text-left">Bezeichnung</th>
          <th class="text-right">Einzelpreis Netto</th>
          <th class="text-right">Gesamt Netto</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let bill of BillDetails">
          <td class="text-center">{{ bill.Menge }}</td>
          <td class="text-left"><b>{{ bill.Beschreibung }}</b>
            <p>Leistungszeitraum: {{ bill.LeistungAb }} - {{ bill.LeistungBis }}</p></td>
          <td class="text-right">{{ bill.Netto }} €</td>
          <td class="text-right">{{ bill.GesamtNetto }} €</td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="d-flex flex-sm-wrap border-top justify-content-end">
      <div class="p-2">
        <div class="d-flex">
          <span class="netto">Betrag netto: </span>
          <span class="betrag ml-1 text-right">{{ BillDetails[0].SummeNetto }} €</span>
        </div>
        <div class="d-flex justify-content-end">
          <span class="mwst">(Zuzüglich der gesetzlichen Mehrwertsteuer)</span>
        </div>

      </div>
    </div>

  </div>
</div>
